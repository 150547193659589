// export const BASE_URL = 'http://unibox-001-site1.ltempurl.com';
// export const BASE_URL = 'http://localhost:5231';
export const BASE_URL = 'https://diwms.ru/api';
export const BASE_URL_FILES = BASE_URL + "/media/";
// export const BASE_URL = 'http://176.57.211.193/api';
// export const BASE_URL = 'http://185.211.170.95/api';

export const IS_PC = window.innerWidth > 750;

export class UserRoles {
    static employeeRole = "employee"
    static userRole = "user"
    // static adminRole = "admin"
    static warehouseOwnerRole = "warehouseOwner"
}