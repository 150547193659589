import { CSSProperties } from "react"
import styles from "./index.module.scss"
import clsx from "clsx";
import { CellModel, MiniBillOutputModel } from "api";
import { Container } from "shared/ui/Container";
import { getLocalDate } from "functions/time";

type Props = {
    isTitleVisible?: boolean;
    title?: string,
    options: Array<TableSelectOption> | null | undefined;
    classNames?: string;
    inlineStyles?: CSSProperties;
}

export type TableSelectOption = {
    onClick : () => void,
    bill?: MiniBillOutputModel;
    cell?: CellModel;
}

const TableSelect = ({title, options, classNames, inlineStyles,isTitleVisible}: Props) => {

    return <div className={clsx(styles.select,classNames)} style={inlineStyles}>
        {isTitleVisible && 
            <div className={styles.title}>
                {title}
            </div>
        }
        <div className={styles.options}>
            {
                options?.map(option => 
                    option.bill != null ?
                    <Container hover inlineStyles={{marginBottom: 10, width: "100%"}} onClick={option.onClick}>
                        <div>Id: {option.bill.id}</div>
                        <div>Организация: {option.bill.organizationName}</div>
                        <div>Запланирования дата реализации: {getLocalDate(option.bill.realizationDate)}</div>
                    </Container>
                    :
                    <Container hover inlineStyles={{marginBottom: 10, width: "100%"}} onClick={option.onClick}>
                        <div>Id: {option.cell?.id}</div>
                        <div>Ячейка: {option.cell?.place}</div>
                    </Container>
                )
            }
        </div>

    </div>
}

export default TableSelect;