import {Container as SharedContainer} from "shared/ui/Container";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { ProductOutputModel, RedactPackageBillModel, useGetBillsPackageBillQuery, useGetOrganizationsQuery, useGetProductsQuery, useGetUsersUserOrganizationsQuery, useGetWarehouseByIdConsumablesQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsPackageBillMutation, usePostBillsRedactPackageBillMutation } from "api";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import {Modal} from "shared/ui/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TextArea } from "shared/ui/TextArea";
import { NavigateButton } from "shared/ui/NavigateButton";
import { ProductModal } from "features/Modals/Product";
import { RedCloseIcon } from "shared/ui/icons/icons";
import { useAppSelector } from "app/store/hooks/redux";
import { getImageUrl } from "functions/image";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: "Цвет", width: "10%" },
    {name: 'Остаток', width: '10%'},
    {name: 'Количество', width: '10%'},
    {name: 'Расходник/Кол-во', width: '15%'},
];

const RedactPackageBillPage = () => {
    const {warehouseOrganizationId, isClient} = useAppSelector(state => state.userReducer);
    
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.AcceptanceBills);
        }
    }, [])
    const {data: bill} = useGetBillsPackageBillQuery({billId: Number(searchParams.get('billId'))});
    const [redactedBill, setRedactedBill] = useState<RedactPackageBillModel>({});

    useEffect(()=> {
        if (bill != null) {
            setSelectedProducts(bill!.productsBills!.map(productBill => ({...productBill.product!, id: productBill.product?.id, organizationName: productBill.product?.organization?.name, images: [getImageUrl(productBill.product?.imageLinks)]})));
            setQuantities(bill!.productsBills!.map(productBill => ({productId: productBill.product!.id!, quantity: productBill.quantity!})));
            var consForSelectedProducts:{pId: number, idx: Date, selectedConsumableId: number, name: string, quantity: number}[] = [];
            
            bill!.productsBills?.forEach(element => {
                if(element.packageType!= null){
                    element.packageType?.packageConsumables?.forEach(c => {
                        consForSelectedProducts.push({pId: element.product!.id!, idx: new Date(), selectedConsumableId: c.consumableId!, name: c.consumable?.name!, quantity: c.quantity!});
                    });
                }
            });
            setConsumablesForAddedproducts(consForSelectedProducts);

            setRedactedBill({
                id: bill?.id,
                realizeDate: (new Date(bill?.realizationDate!)).toISOString(),
                organizationId: bill.organizationId,
                warehouseId: bill.warehouseId,
                note: bill.note,
                externalBillId: bill.externalBillId,
            });
            setSelectedWarehouseOrganizationId(bill.warehouse?.organizationId);
        }
    },[bill])

    const {data: organizations} = useGetOrganizationsQuery();

    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();
    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState(warehouseOrganizationId);
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});
    const {data: consumables, isFetching: isConsumablesFetching} = useGetWarehouseByIdConsumablesQuery({id: redactedBill.warehouseId!}, {skip: redactedBill.warehouseId == null});
    
    const {data: productsByUser} = useGetProductsQuery({warehouseId: redactedBill.warehouseId!, organizationId: redactedBill.organizationId!}, {skip: redactedBill.warehouseId == null || redactedBill.organizationId == null});

    const [redactBillModel] = usePostBillsRedactPackageBillMutation();

    const redactBillModelHandler = async () => {
        try {
            await redactBillModel({
                redactPackageBillModel: {...redactedBill,
                    products: selectedProducts.map(x => ({
                        id: bill?.productsBills?.map(y => y.productId).includes(x.id) ? x.id : -1,
                        productId: x.id,
                        quantity: quantities.find(y => y.productId == x.id)?.quantity, 
                        consumables: consumablesForAddedproducts
                            ?.filter(x2 => x2.pId == x.id)
                            .map(c => ({consumableId: c.selectedConsumableId, quantity: c.quantity}))
                    })),
                }
            }).unwrap();

            setRedactedBill({});
            navigate(LinkPagesEnum.PackageBills);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const navigate = useNavigate();


    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [quantities, setQuantities] = useState<{productId: number; quantity: number;}[]>([]);
    const [consumablesForAddedproducts, setConsumablesForAddedproducts] = useState<{pId: number, idx: Date, selectedConsumableId: number, name: string, quantity: number}[]>([]);
    const [consumablesModalIdVisible, setConsumablesModalIdVisible] = useState<number | null>();
    const [consumableIdModal, setConsumableIdModal] = useState<number | null>();
    const [consumableQuantityModal, setConsumableQuantityModal] = useState<number | null>();

    return (
        <Layout>
                <>
                    <div style={{display: "flex"}}>
                        <NavigateButton text="Назад" inlineStyles={{marginRight: 10}} className={styles.headerButtons} to={LinkPagesEnum.PackageBills}/>
                        {isClient && <Button text="Сохранить" className={styles.headerButtons} onClick={redactBillModelHandler}/>}
                    </div>
                    <div className={styles.title}>
                        Информация
                    </div>
                    <SharedContainer inlineClasses={styles.modal__fieldsContainer}>
                        <>
                            <div className={styles.modal__leftContainer}>
                                <Select 
                                    placeholder="Складская организация"
                                    options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                    onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                    selectedOptionKey={selectedWarehouseOrganizationId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                                <Select 
                                    nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                    isFetching={isWarehousesFetching}
                                    placeholder="Склад"
                                    options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                    onChange={val => setRedactedBill({...redactedBill, warehouseId: val.key})}
                                    selectedOptionKey={redactedBill.warehouseId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                                <Select 
                                    inlineStyles={{marginBottom: 10}} 
                                    placeholder="Организация" 
                                    options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} 
                                    selectedOptionKey={redactedBill.organizationId} 
                                    onChange={val => setRedactedBill({...redactedBill, organizationId: val.key})} 
                                    classNames={styles.modal__field}
                                    />
                                <Input placeholder="Внешний номер накладной" value={redactedBill.externalBillId} onChange={e => setRedactedBill({...redactedBill, externalBillId: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input 
                                    classNamesContainer={styles.field}
                                    placeholder="Ожидаемая дата реализациим"
                                    type="date"
                                    value={redactedBill.realizeDate?.split('T')[0]}
                                    onChange={e => setRedactedBill({...redactedBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                    />
                            </div>
                            <TextArea
                                classNamesContainer={styles.note}
                                placeholder="Коментарий"
                                value={redactedBill.note}
                                onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                                />
                        </>
                    </SharedContainer>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            selectedProducts?.map(el => 
                            {
                                const quantity = quantities.find(x => x.productId === el.id!);

                                return (
                                <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                    <>
                                        <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={el.images![0]} alt={el.name!} /> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[1].width}>{el.name}</TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[2].width}>{el.barcode} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[4].width}>{el.color}</TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[3].width}>{productsByUser?.find(x => x?.id == el.id)?.quantity} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[5].width}><Input type="number" inlineStyles={{width: 63}} onChange={val => setQuantities(prevState => prevState.map(x => x.productId == el.id ? {...x, quantity: Number(val.target.value)} : x))} value={quantity?.quantity?.toString()}/></TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[6].width}>
                                            <div>
                                                {consumablesForAddedproducts.filter(x => x.pId == el.id && x.name != "").map(f => 
                                                    <div className={styles.consumable}>
                                                        {f.name}/{f.quantity} ед.
                                                        <RedCloseIcon inlineStyles={{cursor: "pointer", marginLeft: "5px"}} width="15px" height="15px"  onClick={() => setConsumablesForAddedproducts(consumablesForAddedproducts.filter(y => y.idx != f.idx))}/> 
                                                    </div>)}
                                                
                                                <Button text="Добавить" inlineStyles={{marginTop: 10}} onClick={() => {
                                                    setConsumablesModalIdVisible(el.id);
                                                }}/>
                                            </div>
                                        </TableItemContainer> 
                                    </>
                                </TableItem>
                            )
                        })
                        }
                    </Table>
                    <Modal
                        isCloseCloseIcon
                        isOpen={consumablesModalIdVisible != null}
                        close={() => setConsumablesModalIdVisible(null)}
                        >
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Select 
                                    classNames={styles.kitConsumableSelect} 
                                    selectedOptionKey={consumableIdModal} 
                                    options={consumables?.map(x => ({value: x.name!, key: x.id!}))} 
                                    onChange={(val) => {setConsumableIdModal(val.key)}}
                                    placeholder="Расходник"/>
                                <Input
                                    value={consumableQuantityModal?.toString()}
                                    type="number"
                                    onChange={val => setConsumableQuantityModal(Number(val.target.value))}
                                    />
                                <Button
                                    inlineStyles={{marginTop: 10}}
                                    onClick={() => {
                                        setConsumablesForAddedproducts(prevState => [...prevState, 
                                            {
                                                quantity: consumableQuantityModal!, 
                                                pId: consumablesModalIdVisible!, 
                                                selectedConsumableId: consumableIdModal!, 
                                                idx: new Date(),
                                                name: consumables?.find(x => x.id === consumableIdModal)?.name!
                                            }]);
                                        setConsumablesModalIdVisible(null);
                                    }} 
                                    text="Добавить"/>
                            </div>
                    </Modal>
                    <ProductModal
                        products={productsByUser}
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        selectedProducts={selectedProducts}
                        onProductClick={(el) => {
                            if (selectedProducts.map(x => x.id).includes(el.id))
                            {
                                setQuantities(prevState => prevState.filter(x => x.productId!= el.id));
                                setSelectedProducts(prevState => prevState.filter(x => x.id!= el.id));
                            }
                            else {
                                setQuantities(prevState => [...prevState, {productId: el.id!, quantity: 0}]);
                                setSelectedProducts(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    );
};

export {
    RedactPackageBillPage,
};