import { ArrowIcon } from "shared/ui/icons/icons";
import { Container } from "shared/ui/Container";
import React from "react";
import styles from "./index.module.scss";
import { NavigateButton } from "shared/ui/NavigateButton";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { Button } from "shared/ui/Button";
import { useGetBillsQuery } from "api";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { getBillType } from "functions/billType";
import { getLocalDate } from "functions/time";
import { useNavigate } from "react-router-dom";

const columns: ColumnHeaderType[] = [
    {width: "15%", name: "ID"},
    {width: "15%", name: "Юр.лицо"},
    {width: "15%", name: "Тип"},
    {width: "15%", name: "Создано"},
    {width: "30%", name: "Коментарий"},
]

var date = new Date().toISOString();

const HomeEmployee = () => {
    const {data: bills, isFetching: isBillsFetching} = useGetBillsQuery({date: date});
    const navigate = useNavigate();

    return (
    <div className={styles.left}>
        <div className={styles.container}>
            
            <Container inlineStyles={{width: "100%"}}>
                <>
                    <div className={styles.title}>
                        Узнайте, как работает система
                    </div>
                    <div className={styles.description} style={{marginBottom: 50, marginTop: 3}}>
                        Это займёт всего пару минут
                    </div>
                    <div className={styles.goToInfo}>
                        Подробнее 
                        <ArrowIcon fill="#000" inlineStyles={{rotate: "-90deg", width: "9px", transform: "translateX(-1.5px)", marginLeft: "2px"}}/>
                    </div>
                </>
            </Container>
        </div>
        <Container inlineClasses={styles.fastButtons}>
            <>
                <NavigateButton
                    text="Создать товар"
                    to={LinkPagesEnum.Products}
                    inlineStyles={{marginRight: 10}}
                    />
                <Button
                    text="Экспорт товаров"
                    onClick={() => {}}
                    inlineStyles={{marginRight: 10}}
                    />
            </>
        </Container>
        <div>
            <div className={styles.title} style={{marginBottom: 20}}>
                Задачи(Накладные) на сегодня
            </div>
            <Table columns={columns} isFetching={isBillsFetching}>
                {bills?.map(b => 
                    <TableItem inlineStyles={{marginBottom: 10}} hover onClick={() => {
                        if (b.type == 0) {
                            navigate(LinkPagesEnum.RedactAcceptanceBill + "?billId=" + b.id);
                        }
                        else if (b.type == 1) {
                            navigate(LinkPagesEnum.RedactShipmentBill + "?billId=" + b.id);
                        }
                        else if (b.type == 2) {
                            navigate(LinkPagesEnum.RedactPlacementBill + "?billId=" + b.id);
                        }
                        else if (b.type == 3) {
                            navigate(LinkPagesEnum.RedactPackageBill + "?billId=" + b.id);
                        }
                    }}>
                        <TableItemContainer width={columns[0].width}>{b.id}</TableItemContainer>
                        <TableItemContainer width={columns[1].width}>{b.organizationName}</TableItemContainer>
                        <TableItemContainer width={columns[2].width}>{getBillType(b.type)}</TableItemContainer>
                        <TableItemContainer width={columns[3].width}>{getLocalDate(b.dateCreated)}</TableItemContainer>
                        <TableItemContainer width={columns[4].width}>{b.note}</TableItemContainer>
                    </TableItem>
                )}
            </Table>
        </div>
    </div>
    );
};

export {
    HomeEmployee,
}