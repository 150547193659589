import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import styles from "./Layout.module.scss";
import { useLocation } from "react-router-dom";
import { routes } from "../../pages";
import { useAppDispatch } from "../../app/store/hooks/redux";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import { pageNameSlice } from "../../processes/pageName";
import clsx from "clsx";
import { userSlice } from "../../app/store/reducers/UserSlice";
import { useGetUsersMeQuery } from "api";
import Container from "./Components/Container";

type Props = {
    children: ReactNode,
};

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}

const isIOS = getMobileOperatingSystem() == "iOS";

const Layout = ({children}: Props) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(true);
    const [isAuth, setIsAuth] = useState<boolean>(true);
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { setPageName } = pageNameSlice.actions;

    useEffect( ()=> {
        let pageName2 = routes.find(el => el.routeObject.path == location.pathname)!.pageName;
        dispatch(setPageName(pageName2));

        document.title = pageName2;
    }, [location]);

    const mobileWidth = window.innerWidth;

    useEffect(() => {
        if (mobileWidth <= 1023) 
        {
            setIsOpenMenu(false);
        }
    }, [mobileWidth]);

    // Точно ли нужно ?
    const { setMe } = userSlice.actions
    const {data = null, refetch: refetchMe, isFetching} = useGetUsersMeQuery();

    useEffect(()=>{
        if (!isFetching)
        {
            refetchMe();
        }
    },[location])

    useLayoutEffect(()=> {
        if (data != null)
        {
            dispatch(setMe(data));
        }
    },[data]);

    // ?
    return (
        isAuth ?
        <div className={clsx(styles.wrapper, isIOS ? styles.wrapperForIOS : styles.wrapperForAndroid)}>
            <div className={styles.layout}>
                <Menu/>
                <div className={styles.page}>
                    <Header isOpen={isOpenMenu} setIsOpen={setIsOpenMenu}/>
                    <div className={styles.content}>
                        <Container>
                            {children}
                        </Container>
                    </div>
                </div>
            </div>
        </div>
        : 
        <>{children}</>   
    )
};

export default Layout;