import React, { useState } from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import { useGetDocumentsQuery } from "api";
import {Container as SharedContainer} from "shared/ui/Container";
import { OptionType, Select } from "shared/ui/Select";
import { BASE_URL_FILES } from "app/store/consts";
import { getDocumentTypeName } from "functions/documentType";

type PropsType = {

}

const options: OptionType[] = [{value: "Все", key: -1}, {value: "Накладные приходные", key: 0}, {value: "Накладные отходные", key: 1}];

const DocumentsPage = ({}: PropsType) => {
    const {data: documents } = useGetDocumentsQuery();

    const [selectedOptionKey, setSelectedOption] = useState(0);
    const filteredDocuments = documents?.filter(document => selectedOptionKey != 0 ? document.documentType == selectedOptionKey : true);

    return (
        <Layout>
                <>
                    <div className={styles.header}>
                        <Select
                            placeholder="Тип"
                            options={options}
                            selectedOptionKey={selectedOptionKey}
                            onChange={val => setSelectedOption(val.key)}
                            classNames={styles.select}
                            />
                    </div>
                    <div className={styles.container}>
                        {filteredDocuments?.map( item => 
                            <SharedContainer inlineClasses={styles.document}>
                                <>
                                    <div>
                                        <div className={styles.document__name}>
                                            {getDocumentTypeName(item.documentType!) + " №" + item.id}
                                        </div>
                                        <div className={styles.document__date}>
                                            {(new Date(item.objectCreateDate!)).toLocaleString()}
                                        </div>
                                    </div>
                                    <div onClick={()=> {
                                        if (item.file && item.file.fileName) {
                                            window.open(BASE_URL_FILES + item.file.fileName)!.focus();
                                        }
                                    }}>
                                        {/* <img src="assets/svg/pdf_icon.svg"/> */}
                                        {item.documentExtension == 0
                                            ? <img src="assets/svg/docx_icon.svg"/>
                                            : <img className={styles.document__file} src="assets/svg/xlsx_icon.svg"/>
                                            }
                                    </div>
                                </>
                            </SharedContainer>
                            )} 
                    </div>
                </>
        </Layout>
    )
};

export { DocumentsPage };