import React,{ useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router";
import TableSelect from "entities/TableSelect";
import { CellModel, useGetBillsQuery, useGetCellsQuery, usePostProcessesAddProcessMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { useAppSelector } from "app/store/hooks/redux";
import { NavigateButton } from "shared/ui/NavigateButton";
import Title from "shared/ui/Title/Title";
import Layout from "widgets/Layout/Layout";
import { BillStatusEnum } from "enums/billStatus";
import { BillType } from "enums/billType";

enum Stage {
    Zero = 0,
    First = 1,
    Second = 2,
    Third = 3,
}

const ShipPage = () => {
    const [stage, setStage] = useState(Stage.First);
    
    const {warehouseId} = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    
    const { data : shipmentCells } = useGetCellsQuery({warehouseId: warehouseId!, cellType: CellTypeEnum.Shipment}, {skip: warehouseId == null});
    const { data: shipmentBills } = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Shipment, warehouseId: warehouseId!}, {skip: warehouseId == null});

    const [ selectedAcceptanceCell, setSelectedAcceptanceCell ] = useState<CellModel | null>();
    
    const [createProcess] = usePostProcessesAddProcessMutation();
    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedAcceptanceCell == null ? 0 : selectedAcceptanceCell!.id
            }).unwrap();

            navigate({pathname: LinkPagesEnum.ShipProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(()=> {
        if (shipmentCells?.length == 1)
        {
            setStage(Stage.Second);
            setSelectedAcceptanceCell(shipmentCells[0]);
        }
    }, [shipmentCells])

    if (warehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите складскую организацию и склад в настройках</Title>
            <NavigateButton
                to={LinkPagesEnum.Settings}
                text="Перейти в настройки"
                />
        </div>;
    }

    return (
        <Layout>
            <div className={styles.wrapper}>
                {stage == Stage.First
                    &&
                    <div className={styles.container}>
                        <div className={styles.title}>
                            Просканируйте ячейку отгрузки
                        </div>
                        <div className={styles.subTitle}>
                            или выберете из списка 
                        </div>
                        <TableSelect 
                            isTitleVisible={false}
                            options={shipmentCells?.map(el => { return { cell: el, onClick: () => {
                                setSelectedAcceptanceCell(el);
                                setStage(Stage.Second);
                            }}})}
                        />
                    </div>  
                    
                }
                {stage == Stage.Second
                    &&
                    <>
                        <div className={styles.title}>
                            Ячейка выбрана: {selectedAcceptanceCell?.name}
                        </div>
                        <div className={styles.subTitle}>
                            Отсканируйте товар накладной или выберите её из списка.
                        </div>
                        <TableSelect title="Выберите накладную" options={ shipmentBills?.map(el => {return {bill: el, onClick:() => createProcesshandler(el.id!)}})}/>
                    </>
                }
                {/* {
                    stage == Stage.Third
                    && 
                        <>
                            
                            <div className={styles.title}>
                                Ячейка выбрана: {selectedAcceptanceCell?.name}
                            </div>
                            
                            <div className={styles.subTitle}>
                                По данному баркоду несколько приходных накладных, выберите нужную. 
                            </div>
                            <TableSelect 
                                title="Выберите накладную" 
                                options={shipmentBillsByProductBarcode?.map(el => { return {name:el.id!.toString(), onClick:() => createProcesshandler(el.id!)}}) || []}
                                />
                        </>
                } */}
            </div>
        </Layout>
    );
};

export {ShipPage};