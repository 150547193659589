export const IsArrayIsNotEmpty = (array: any | undefined | null) => {
	if (array == null) return false;
	
	if (Array.isArray(array)) { 
		if (array.length != 0) {
			return true;
		}
        return false;
	}

	return false;
}