import React, { useEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { ProductOutputModel, RedactBillModel, useGetOrganizationsQuery, useGetProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsBillMutation, usePostBillsExcelHonestSignsMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import {Modal} from "shared/ui/Modal";
import img from "shared/assets/images/box.png";
import { NavigateButton } from "shared/ui/NavigateButton";
import { ProductModal } from "features/Modals/Product";
import { TerminalScan } from "widgets/TerminalScan";
import Title from "shared/ui/Title/Title";
import { RedCloseIcon } from "shared/ui/icons/icons";
import { Container } from "shared/ui/Container";
import ImportButton from "features/ImportButton/ImportButton";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Наименование", width: "20%"},
    {name: "Штрих-код", width: "15%"},
    {name: "Цвет", width: "10%"},
    {name: "Размер", width: "15%"},
    {name: "Цена услуги", width: "10%"},
    {name: "Количество", width: "10%"},
]
const transporters: OptionType[] = [
    {value: "Склад", key: 1},
    {value: "Самостоятельно", key: 2},
    {value: "Транспортная компания", key: 3},
];

const AddingAcceptanceBillPage = ({}: Props) => {
    const {warehouseId, organizationId, warehouseOrganizationId} = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<ProductOutputModel[]>([]);
    const [quantities, setQuantities] = useState<{id: number, quantity: number}[]>([]);

    const [newBill, setNewBill] = useState<RedactBillModel>({
        realizeDate: (new Date()).toISOString(),
        carId: 1,
        type: 0,
        transporter: transporters[0].value,
        warehouseId: warehouseId ?? undefined,
        organizationId: organizationId ?? undefined,
    });
    
    const {id: userId} = useAppSelector(el => el.userReducer);

    const [addAcceptanceBillQuery] = usePostBillsBillMutation();
    const {data: organizations} = useGetOrganizationsQuery();
    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();
    const {data: productsByUser} = useGetProductsQuery({clientId: userId, warehouseIdForCellsQuantity: newBill.warehouseId}, {skip: newBill.warehouseId == null});

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState(warehouseOrganizationId);
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});

    useEffect(()=> {
        if (organizations != null && organizations.length == 1) 
        {
            setNewBill({...newBill, organizationId: organizations[0].id!});
        }
    }, [organizations]);

    const addAcceptanceBill = async () => {
        try {
            await addAcceptanceBillQuery({redactBillModel: {...newBill, products: addedProducts.map(product => {
                const quantity = quantities.find(quantity => quantity.id == product.id)?.quantity;
                const hs = honestSigns.filter(h => h.prId == product.id).map(h => h.qrCode!);
                return {...product, quantity: quantity, honestSigns: hs};
            })}}).unwrap();

            navigate(LinkPagesEnum.AcceptanceBills);
        }
        catch(err) {

        }
    };

    const [honestSignsByProductId, setHonestSignsByProductId] = useState<number | null>(null);
    const [honestSigns, setHonestSigns] = useState<{prId: number, qrCode: string}[]>([]);
    const [qrCode, setQrCode] = useState("");
    const [qrCodeIsFocus, setQrCodeIsFocus] = useState(false);
    
    const [getHs] = usePostBillsExcelHonestSignsMutation();
    return (
        <Layout>
                <>
                    <div>
                        <div className={styles.header}>
                            <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.AcceptanceBills}/>
                            <Button text="Создать" onClick={addAcceptanceBill}/>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Информация
                                </div>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                        <Select 
                                            placeholder="Складская организация"
                                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                            selectedOptionKey={selectedWarehouseOrganizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                            isFetching={isWarehousesFetching}
                                            placeholder="Склад"
                                            options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, warehouseId: val.key})}
                                            selectedOptionKey={newBill.warehouseId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            placeholder="Организация"
                                            options={organizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, organizationId: val.key})}
                                            selectedOptionKey={newBill.organizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={newBill.externalBillId}
                                            onChange={e => setNewBill({...newBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setNewBill({...newBill, note: e.target.value})}
                                        value={newBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Доставка
                                </div>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={newBill.sender}
                                        onChange={e => setNewBill({...newBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setNewBill({...newBill, transporter: val.value})}
                                        selectedOptionKey={transporters.find(x => x.value === newBill.transporter)?.key}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={newBill.realizeDate!.split('T')[0]}
                                        onChange={e => setNewBill({...newBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={newBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setNewBill({...newBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={newBill.palletsCount?.toString()}
                                        onChange={e => setNewBill({...newBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={newBill.deliveryNote}
                                        onChange={e => setNewBill({...newBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>
                            <Table columns={columns}>
                                {addedProducts?.map(product => <TableItem inlineStyles={{marginBottom: 10}} hover onClick={(e) => {setHonestSignsByProductId(product.id!)}}>
                                        <>
                                            <TableItemContainer width={columns[0].width}><img src={product.images == null ? img : product.images[0]} className={styles.img}/></TableItemContainer>
                                            <TableItemContainer width={columns[1].width}><div className={styles.text}>{product.name}</div></TableItemContainer>
                                            <TableItemContainer width={columns[2].width}><div className={styles.text}>{product.barcode}</div></TableItemContainer>
                                            <TableItemContainer width={columns[3].width}><div className={styles.text}>{product.color}</div></TableItemContainer>
                                            <TableItemContainer width={columns[4].width}><div className={styles.text}>-</div></TableItemContainer>
                                            <TableItemContainer width={columns[5].width}><div className={styles.text}>{product.serviceProductPrice}</div></TableItemContainer>
                                            <TableItemContainer width={columns[6].width}>
                                                <Input 
                                                    type="number"
                                                    onChange={e => 
                                                        setQuantities(prevState =>
                                                            {   
                                                                if (prevState.find(pv => pv.id == product.id)) {
                                                                    return prevState.map(pv => pv.id == product.id 
                                                                        ? {...pv, quantity: Number(e.target.value)} 
                                                                        : pv
                                                                        )
                                                                }
                                                                return [...prevState, {id: product.id!, quantity: Number(e.target.value)}]
                                                            } 
                                                            )
                                                        } 
                                                    value={quantities!.find(q => q.id == product.id)!.quantity.toString()}
                                                    />
                                            </TableItemContainer>
                                        </>
                                    </TableItem>)}
                            </Table>
                        </div>
                    </div>
                    <Modal
                        isOpen={honestSignsByProductId != null}
                        close={() => setHonestSignsByProductId(null)}
                        inlineStyles={{width: 590}}
                        >
                        <div>
                            <Title inlineStyles={{textAlign: "center", marginBottom: 20}}>
                                Сканируйте или введите
                                честный знак
                            </Title>
                            <div style={{display: "flex", marginBottom: 20}}>
                                <Input
                                    inlineStylesContainer={{width: 300, marginRight: 10}}
                                    onClick={() => setQrCodeIsFocus(true)}
                                    onFocus={() => setQrCodeIsFocus(true)}
                                    onBlur={() => setQrCodeIsFocus(false)}
                                    type="text"
                                    value={qrCode}
                                    onChange={e => setQrCode(e.target.value)}
                                    />
                                <Button text="Добавить" inlineStyles={{marginRight: 10}} onClick={() => {
                                    setHonestSigns(prevState => [...prevState, {qrCode: qrCode, prId: honestSignsByProductId!}]);
                                    setQrCode("");
                                    }} />
                                <ImportButton text="Импорт" addFile={async (file) => {
                                        var res = await getHs({body: {File: file}}).unwrap();

                                        setHonestSigns(prevState => [...prevState, ...res.map(el => ({prId: honestSignsByProductId!, qrCode: el.honestSign!}))]);
                                    }} />
                            </div>
                            {honestSigns.filter(el => el.prId == honestSignsByProductId).map((honestSign, idx) =>
                            <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                                <Container inlineStyles={{marginRight: 10}}>
                                    <div style={{fontSize: 16}}>{idx + 1}. {honestSign.qrCode}</div>
                                </Container>
                                <RedCloseIcon inlineStyles={{cursor: "pointer"}} width="20px" height="20px" onClick={() => {
                                    setHonestSigns(prevState => prevState.filter(el => el.qrCode!= honestSign.qrCode));
                                    }} />
                            </div>)}
                            <TerminalScan
                                isFixed
                                isFocus={honestSignsByProductId != null && qrCodeIsFocus == false}
                                onChange={(val) => {
                                    setHonestSigns(prevState => [...prevState, {qrCode: val, prId: honestSignsByProductId!}]);
                                }}
                                />
                        </div>
                    </Modal>
                    <ProductModal
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        products={productsByUser}
                        selectedProducts={addedProducts}
                        onProductClick={(el) => {
                            if (addedProducts.map(x => x.id).includes(el.id)) {
                                setAddedProducts(prevState => prevState.filter(x => x.id != el.id));
                                setQuantities(prevState => prevState.filter(x => x.id != el.id));
                            }
                            else {
                                setAddedProducts(prevState => [...prevState, el]);
                                setQuantities(prevState => [...prevState, {id: el.id!, quantity: 0}])
                            }}
                        }
                        />
                </>
        </Layout>
    )
};

export {AddingAcceptanceBillPage};