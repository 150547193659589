import React, { useState } from "react";
import styles from "./index.module.scss";
import { TerminalScan } from "widgets/TerminalScan";
import Layout from "widgets/Layout/Layout";
import { Button } from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetBillsQuery, useGetCellsQuery, usePostProcessesPackageAddProcessMutation } from "api";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { useAppSelector } from "app/store/hooks/redux";
import Title from "shared/ui/Title/Title";
import { NavigateButton } from "shared/ui/NavigateButton";
import { BillType } from "enums/billType";
import { BillStatusEnum } from "enums/billStatus";
import TableSelect from "entities/TableSelect";

enum StageEnum {
    Zero = 0, 
    First = 1,
    Second = 2,
}

const PackagePage = () => {
    const [isFocus, setIsFocus] = useState(true);
    const onChange = (val: string) => {
        console.log('onChange' + val);
    }

    const [stage, setStage] = useState(StageEnum.Zero);
    const navigate = useNavigate();
    
    const {warehouseId} = useAppSelector(state => state.userReducer);

    const {data: packageBills} = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Package, warehouseId: warehouseId!}, {skip: warehouseId == null});

    const {data: packageCells} = useGetCellsQuery({warehouseId: warehouseId!, cellType: CellTypeEnum.Package}, {skip: warehouseId == null});

    const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
    
    const [createProcess] = usePostProcessesPackageAddProcessMutation();
    const createProcesshandler = async (selectedCellId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedCellId
            }).unwrap();

            navigate({pathname: LinkPagesEnum.PackageProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    if (warehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите складскую организацию и склад в настройках</Title>
            <NavigateButton
                to={LinkPagesEnum.Settings}
                text="Перейти в настройки"
                />
        </div>;
    }

    return (
        <>
            <Layout>
                    {stage == StageEnum.Zero &&
                    (
                        <div className={styles.container}>
                            <Button
                                text="На главную"
                                onClick={() => navigate(LinkPagesEnum.Home)}
                                />
                            <TableSelect 
                                options={packageBills?.map(el => { return { bill: el, onClick: () => {setSelectedBillId(el.id!); setStage(StageEnum.First)}}})}
                                title="Выберите накладную"
                            />
                        </div>
                    )
                    }
                    {stage == StageEnum.First &&
                    (
                        <div className={styles.container}>
                            <Button
                                text="На главную"
                                onClick={() => navigate(LinkPagesEnum.Home)}
                                />
                            <TableSelect 
                                options={packageCells?.map(el => { return { cell: el, onClick: () => createProcesshandler(el.id!)}}) ?? []}
                                title="Выберите ячейку упаковки"
                            />
                        </div>
                    )
                    }
            </Layout>
            <TerminalScan
                inlineClasses={styles.scanInput}
                isFocus={isFocus}
                onChange={onChange}
                />
        </>
    )
};

export {PackagePage};