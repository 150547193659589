import React, { useState } from "react";
import styles from "./index.module.scss";
import {Button} from "shared/ui/Button";
import Layout from "widgets/Layout/Layout";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { useGetWarehouseEmployeeCooperationsQuery, useGetWarehouseOrganizationsByUserQuery, usePostWarehouseEmployeeCreateCooperationRequestMutation } from "api";
import { OptionType, Select } from "shared/ui/Select";
import {Modal} from "shared/ui/Modal";
import { Input } from "shared/ui/Input";
import { getLocalDate } from "functions/time";


export const columns: Array<ColumnHeaderType> = [
    {name: 'Id', width: '10%'},
    {name: 'Почта', width: '15%'},
    {name: 'Имя', width: '15%'},
    {name: 'Телефон', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
];

const options: OptionType[] = [
  {key: 1, value: "ID"},
  {key: 2, value: "Почта"},
  {key: 3, value: "Должность"},
  {key: 4, value: "Склад"}
];

const EmployeesPage = ({}) =>  {
  const {data: employees, refetch: refetchEmployees, isFetching: isEmployeesFetching } = useGetWarehouseEmployeeCooperationsQuery();
  const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsByUserQuery();

  const [searchText, setSearchText] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<number[] | null>([2]);

  const filteredData = employees?.filter(el => filterDataEl(searchText, selectedOptions, [
      {value: el.id, key: 1},
      // {value: el.user?.email, key: 2},
      // {value: el.type?.type, key: 3},
      // {value: el.warehouse?.name, key: 4},
  ]));
  
  const [selectedOption, setSelectedOption] = useState(2);

  const [isDesc, setIsDesc] = useState(true);

  const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
      {value2: el2.id, value1: el1.id, key: 1},
      // {value2: el2.user?.email, value1: el1.user?.email, key: 2},
      // {value2: el2.type?.type, value1: el1.type?.type, key: 3},
      // {value2: el2.warehouse?.name, value1: el1.warehouse?.name, key: 4},
  ], selectedOption, isDesc));

	const [idForRequest, setIdForRequest] = useState("");
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>();

	const [isModalSendRequestVisible, setIsModalSendRequestVisible] = useState(false);

	const [sendRequestEmployee, {isLoading: isLoadingEmployee}] = usePostWarehouseEmployeeCreateCooperationRequestMutation();

	const sendRequestHandler = async () => {
		if (selectedOrganizationId == null) {
			return alert("Выберите организацию");
		}
		await sendRequestEmployee({employeeId: Number(idForRequest), organizationId: selectedOrganizationId}).unwrap();
		setIsModalSendRequestVisible(false)
	}

  return (
    <Layout>
        <div style={{display: 'flex', justifyContent:'space-between', marginBottom: 20}}>
            <div style={{display: "flex"}}>
                <Button
                    inlineStyles={{marginRight: 10}}
                    text="Обновить"
                    onClick={refetchEmployees}
                    />
                <Button 
                  inlineStyles={{marginRight: 10}}  
                  text="Добавить" 
                  onClick={() => setIsModalSendRequestVisible(true)}
                  />
                {/* <Button 
                  inlineStyles={{marginRight: 10}}  
                  text="Редактировать"
                  onClick={() => {}}
                  />
                <Button 
                  text="Заблокировать" 
                  onClick={() => {}}
                  /> */}
            </div>
            <div style={{display: 'flex'}}>
                <SearchInput 
                    value={searchText} 
                    onChange={setSearchText}
                    inlineStyles={{marginRight: 10}}
                    classNames={styles.searchInput}
                    />
                <MultipleSelect 
                    text="Параметры"
                    options={options} 
                    selectedOptionKeys={selectedOptions} 
                    setSelectedOptions={setSelectedOptions}
                    inlineStyles={{marginRight: 10, width: 135}}
                    />
                <Sort 
                    options={options}
                    selectedOptionKey={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setIsDesc={setIsDesc}
                    isDesc={isDesc}
                    selectClassNames={styles.select}
                    />
            </div>
        </div>
        <Table columns={columns} isFetching={isEmployeesFetching}>
            {sortedData?.map(el => (
                    <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                        <>
                          <TableItemContainer width={columns[0].width}>{el.id}</TableItemContainer>
                          <TableItemContainer width={columns[1].width}>{el.employee?.user?.email}</TableItemContainer>
                          <TableItemContainer width={columns[2].width}>{el.employee?.user?.name}</TableItemContainer>
                          <TableItemContainer width={columns[3].width}>{el.employee?.user?.telephone}</TableItemContainer>
                          <TableItemContainer width={columns[3].width}>{getLocalDate(el.objectCreateDate)}</TableItemContainer>
                        </>
                    </TableItem>
            ))
        }
        </Table>
        <Modal 
			isLoader={isLoadingEmployee}
			isCloseCloseIcon
			isOpen={isModalSendRequestVisible}
			close={() => setIsModalSendRequestVisible(false)}
			>
			<div>
				<Select
					inlineStyles={{marginBottom: 10}}
					placeholder="Складская организация"
					options={warehouseOrganizations?.map(organization => ({key: organization.id!, value: organization.name!}))}
					selectedOptionKey={selectedOrganizationId}
					onChange={val => setSelectedOrganizationId(val.key)}
					/>
				<Input
					value={idForRequest}
					type="number"
					placeholder="Id специалиста"
					onChange={e => setIdForRequest(e.target.value)}
					inlineStylesContainer={{marginBottom: 20, width: 300}}
					/>
				<Button text="Отправить" onClick={sendRequestHandler} inlineStyles={{margin: "0 auto"}}/>
			</div>
		</Modal>
    </Layout>
  )
}

export {EmployeesPage};