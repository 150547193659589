import {Container as SharedContainer} from "shared/ui/Container";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { ProductDetail, ProductDetailCell, ProductOutputModel, RedactPlacementBillModel, useGetBillsPlacementBillQuery, useGetCellsQuery, useGetProductsByIdDetailsQuery, useGetProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsRedactPlacementBillMutation } from "api";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TextArea } from "shared/ui/TextArea";
import { NotMore } from "functions/math";
import { ProductModal } from "features/Modals/Product";
import { getImageUrlByStrings } from "functions/image";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Остаток в ячейке', width: '15%'},
    {name: "Из", width: "10%" },
    {name: "В", width: "10%" },
    {name: 'Количество', width: '10%'},
];

const RedactPlacementBillPage = () => {
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.ShipmentBills);
        }
    }, [])

    const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsQuery();

    const {data: bill} = useGetBillsPlacementBillQuery({billId: Number(searchParams.get('billId'))});
    const {data: products, isFetching: isProductsFetching} = useGetProductsQuery({warehouseId: bill?.warehouseId}, {skip: bill?.warehouseId == null});

    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: bill?.warehouse?.organizationId!}, {skip: bill?.warehouse?.organizationId == null});

    // const {data: details, isFetching: isBillDetailsFetching} = useGetBillsByIdDetailsQuery({id: Number(searchParams.get('billId'))});

    useEffect(() => {
        if (products != null && bill != null) {
            var cells: ProductDetailCell[] = [];

            bill?.billProducts?.forEach(pr => {
                cells.push({productCellId: pr.productCellId, productId: pr.productCell?.productId, cellPlace: pr.productCell?.cell?.place, quantity: pr.productCell?.quantity})
            });
            
            setSelectedProductCells(cells);
            setSelectedDetails(bill.billProducts!.map(el => ({key: bill.warehouseId + " " + el.productCell?.productId + (el.productCell?.packageTypeId != null ? " " + el.productCell?.packageTypeId : "") })));
            setSelectedProducts(products.filter(el => cells.map(x => x.productId).includes(el.id)));
            setQuantities(bill.billProducts!.map(productBill => ({productCellId: productBill.productCellId!, quantity: productBill.quantity!})));
            setToCellsForselectedProductCells(bill.billProducts!.map(el => ({toCellId: el.toCellId!, productCellId: el.productCellId!})));
        }
    }, [products]);
    
    useEffect(() => {
        if (bill != null) 
            setRedactedBill({
                billId: bill?.id,
                realizationDate: (new Date(bill?.realizationDate!)).toISOString(),
                // billProducts: bill?.billProducts?.map(el => ({
                //     id: el.id,
                //     productCellId: el.productCellId,
                //     quantity: el.quantity,
                //     toCellId: el.toCellId,
                // })) || [],
                note: bill?.note,
                warehouseId: bill?.warehouseId
            });
    }, [bill])

    const [redactedBill, setRedactedBill] = useState<RedactPlacementBillModel>({});
    const {data: allCells} = useGetCellsQuery({warehouseId: bill?.warehouseId}, {skip: bill?.warehouseId == null});
    const [redactPlacementBill] = usePostBillsRedactPlacementBillMutation();

    const addPlacementBillHandler = async () => {
        try {
            await redactPlacementBill({
                redactPlacementBillModel: {...redactedBill,
                    billProducts: selectedProductCells.map(el => {
                        const quantity = quantities.find(c => c.productCellId == el.productCellId);
                        const toCell = toCellsForselectedProductCells.find(c => c.productCellId == el.productCellId);
                        return ({
                        id: bill?.billProducts?.find(x => x.productCellId == el.productCellId) == null ? -1 : el.productCellId,
                        productCellId: el.productCellId,
                        quantity: quantity?.quantity,
                        toCellId: toCell?.toCellId,
                    })})
                }
            }).unwrap();

            setRedactedBill({});
            navigate(LinkPagesEnum.PlacementBills);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const navigate = useNavigate();
    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [selectedProductIdForVisibleDetails, setSelectedProductsIdForVisibleDetails] = useState<number | null>(null);
    const {data: productDetails, isFetching: isProductDetailsFetching} = useGetProductsByIdDetailsQuery({id: selectedProductIdForVisibleDetails!, warehouseId: redactedBill!.warehouseId!}, {skip: selectedProductIdForVisibleDetails == null || redactedBill?.warehouseId == null});
    const [selectedDetails, setSelectedDetails] = useState<ProductDetail[]>([]);
    const [selectedDetailForVisibleCells, setSelectedDetailForVisibleCells] = useState<ProductDetail | null>(null);
    const [selectedProductCells, setSelectedProductCells] = useState<ProductDetailCell[]>([]);
    const [toCellsForselectedProductCells, setToCellsForselectedProductCells] = useState<{toCellId: number, productCellId: number}[]>([]);
    const [quantities, setQuantities] = useState<{quantity: number, productCellId: number}[]>([]);
    
    return (
        <Layout>
                <>
                    <div style={{display: "flex"}}>
                        <Button text="Назад" inlineStyles={{marginRight: 10}} className={styles.headerButtons} onClick={() => navigate(LinkPagesEnum.PlacementBills)}/>
                        <Button text="Сохранить" className={styles.headerButtons} onClick={addPlacementBillHandler}/>
                    </div>
                    <div className={styles.title}>
                        Информация
                    </div>
                    <SharedContainer inlineClasses={styles.modal__fieldsContainer}>
                        <>
                            <div className={styles.field}>
                                <Input 
                                    placeholder="Ожидаемая дата реализациим"
                                    type="date"
                                    value={redactedBill.realizationDate?.split('T')[0]}
                                    onChange={e => setRedactedBill({...redactedBill, realizationDate: (new Date(e.target.value).toISOString())})}
                                    inlineStylesContainer={{marginBottom: 10}}
                                    />
                                <Select 
                                    isFetching={isWarehouseOrganizationsFetching}
                                    placeholder="Складская организация"
                                    options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                    onChange={val => {}}
                                    selectedOptionKey={bill?.warehouse?.organizationId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                                <Select 
                                    nullOptionsText={bill?.warehouse?.organizationId ? "Нет записей" : "Выберите складскую организацию"}
                                    isFetching={isWarehousesFetching}
                                    placeholder="Склад"
                                    options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                    onChange={val => {}}
                                    selectedOptionKey={bill?.warehouseId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                            </div>
                            <TextArea
                                classNamesContainer={styles.note}
                                placeholder="Коментарий"
                                value={redactedBill.note}
                                onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                                />
                        </>
                    </SharedContainer>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            selectedProductCells?.map(cell => {
                                const product = products?.find(x => x.id == cell.productId);
                                const toCell = toCellsForselectedProductCells.find(x => x.productCellId == cell.productCellId);
                                const quantity = quantities?.find(x => x.productCellId == cell.productCellId);

                                return (
                                    <TableItem classNames={styles.item} inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={getImageUrlByStrings(product?.images)} alt={product?.name!}/></TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}>{product?.name}</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}>{product?.barcode} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{cell.quantity} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}>{cell.cellPlace}</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}>
                                                <Select 
                                                    classNames={styles.select} 
                                                    placeholder="ячейки" 
                                                    selectedOptionKey={toCell?.toCellId} 
                                                    options={allCells?.map(el => ({value: el.name!, key: el.id!}))} 
                                                    onChange={val => {
                                                        const index = toCellsForselectedProductCells.findIndex(x => x.productCellId == cell.productCellId);
                                                        if (index!= -1)
                                                        {
                                                            setToCellsForselectedProductCells(prevState => prevState.filter((_, i) => i!= index));
                                                            setToCellsForselectedProductCells(prevState => [...prevState, {toCellId: val.key!, productCellId: cell.productCellId!}]);
                                                        }
                                                        else
                                                            setToCellsForselectedProductCells(prevState => [...prevState, {toCellId: val.key!, productCellId: cell.productCellId!}]);
                                                    }}/>
                                            </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}>
                                                <Input 
                                                    type="number" 
                                                    inlineStyles={{width: 63}} 
                                                    onChange={val => {
                                                        const index = quantities.findIndex(x => x.productCellId == cell.productCellId);
                                                        if (index!= -1)
                                                            setQuantities(prevState => prevState.map((el, i) => i == index ? {quantity: NotMore(parseInt(val.target.value), cell.quantity!), productCellId: cell.productCellId!} : el));
                                                        else
                                                            setQuantities(prevState => [...prevState, {quantity: NotMore(parseInt(val.target.value), cell.quantity!), productCellId: cell.productCellId!}]);
                                                    } } 
                                                    value={quantity?.quantity.toString()}/>
                                            </TableItemContainer> 
                                        </>
                                    </TableItem>
                                )
                            })
                        }
                    </Table>
                    <ProductModal
                        isLoader={isProductDetailsFetching || isProductsFetching}
                        close={() => setAddProductModalVisible(false)}
                        detailsBack={() => setSelectedProductsIdForVisibleDetails(null)}
                        cellsBack={() => setSelectedDetailForVisibleCells(null)}
                        isOpen={addProductModalVisible}
                        products={products}
                        onProductClick={(el) => { 
                            console.log(el.id);
                            console.log(selectedProductIdForVisibleDetails);
                            
                            setSelectedProductsIdForVisibleDetails(el.id!);
                        }}
                        selectedProducts={selectedProducts}
                        details={selectedProductIdForVisibleDetails != null ? productDetails : null}
                        onDetailClick={(el) => setSelectedDetailForVisibleCells(el)}
                        selectedDetails={selectedDetails}
                        cells={selectedDetailForVisibleCells?.productDetailCells}
                        selectedCells={selectedProductCells}
                        onCellClick={(el) => { 
                            const product = products!.find(p => p.id == el.productId)!;

                            if (selectedProductCells?.map(x => x.productCellId).includes(el.productCellId))
                            {
                                setSelectedProductCells(prevState => prevState.filter(x => x.productCellId != el.productCellId));

                                var idxForDelete = selectedProducts.map(x => x.id).indexOf(product.id);
                                if (idxForDelete != -1) {
                                    selectedProducts.splice(idxForDelete, 1);
                                }
                                
                                var idxForDelete2 = selectedDetails.map(x => x.key).indexOf(selectedDetailForVisibleCells!.key);
                                if (idxForDelete2 != -1) {
                                    selectedDetails.splice(idxForDelete2, 1);
                                }
                            }
                            else {
                                setSelectedProducts(prevState => [...prevState, product]);
                                setSelectedDetails(prevState => [...prevState, selectedDetailForVisibleCells!]);
                                setSelectedProductCells(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    );
};

export {
    RedactPlacementBillPage,
};