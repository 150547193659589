import Layout from "widgets/Layout/Layout";
import React, { useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import {Container} from "shared/ui/Container";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { CreatePaymentBillDetailModel, CreatePaymentBillModel, useGetOrganizationsQuery, useGetPaymentsBillsQuery, useGetWarehouseOrganizationsByIdEmployeesQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostPaymentsMutation } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { getColorByPaymentBillStatus, getPaymentBillStatus } from "functions/paymentBillStatus";
import { FileExportIcon } from "shared/ui/icons/icons";
import { Loader } from "shared/ui/Loader";
import { OptionType, Select } from "shared/ui/Select";
import {Modal} from "shared/ui/Modal";
import Title from "shared/ui/Title/Title";
import { TextArea } from "shared/ui/TextArea";
import { Input } from "shared/ui/Input";
import { PaymentDetailType } from "enums/paymentType";
import { useAppSelector } from "app/store/hooks/redux";

const options: OptionType[] = [
    {value: "Юр.лицо", key: 1},
    {value: "ID Счёта", key: 2},
    {value: "Статус оплаты", key: 3},
    {value: "Сумма выплаты", key: 4},
];

const PaymentBillsPage = () => {
    const {warehouseId, warehouseOrganizationId, organizationId} = useAppSelector(state => state.userReducer);

    const {data: bills, refetch, isFetching: isBillsFetching } = useGetPaymentsBillsQuery();
    const {data: organizations, isFetching: isOrganizationsFetching} = useGetOrganizationsQuery()
    const [searchText, setSearchText] = useState("");
    
    const navigate = useNavigate();

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([2]);

    const filteredData = bills?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.organization?.name, key: 1},
        {value: el.id, key: 2},
        {value: getPaymentBillStatus(el.paymentStatus!), key: 3},
        // {value: el.paymentAmount, key: 4},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(2);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.organization?.name, value1: el1.organization?.name, key: 1},
        {value2: el2.id,  value1: el1.id, key: 2},
        {value2: getPaymentBillStatus(el2.paymentStatus!), value1: getPaymentBillStatus(el1.paymentStatus!), key: 3},
        // {value2: el2.paymentAmount, value1: el1.paymentAmount, key: 4},
    ], selectedOption, isDesc));

    const [isModalAddingPaymentBillVisible, setIsModalAddingPaymentBillVisible] = useState(false);
    const [isFirstModalStage, setIsFirstModalStage] = useState(true);
    const [createBill] = usePostPaymentsMutation();
    const [newBill, setNewBill] = useState<CreatePaymentBillModel>({
        createPaymentBillDetailModels: [],
        warehouseId: warehouseId ??  undefined,
        organizationId: organizationId ?? undefined,
    });
    
    const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsQuery();

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState(warehouseOrganizationId);
    const {data: employees, isFetching: isEmployeesFetching} = useGetWarehouseOrganizationsByIdEmployeesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});
    
    const [detail, setDetail] = useState<CreatePaymentBillDetailModel>({});

    const handleCreateBill = async () => {
        try {
            await createBill({createPaymentBillModel: newBill}).unwrap();

            setIsModalAddingPaymentBillVisible(false);
            setIsFirstModalStage(true);
            setNewBill({createPaymentBillDetailModels: []});
        }
        catch {
        }
    }
    
    const {isClient} = useAppSelector(state => state.userReducer);

    return (
        <Layout>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        {!isClient && <Button text="Создать счёт" onClick={() => setIsModalAddingPaymentBillVisible(true)}/>}
                    </div>
                    <div style={{display: 'flex'}}>
                        <SearchInput 
                            value={searchText} 
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            classNames={styles.searchInput}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={options} 
                            selectedOptionKeys={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10}}
                            inlineClasses={styles.select}
                            />
                        <Sort 
                            options={options}
                            selectedOptionKey={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
                <div className={styles.body}>
                    {isBillsFetching && <Loader inlineContainerClasses={styles.loader}/>}
                    {!isBillsFetching && sortedData?.map(paymentBill => 
                        <Container inlineClasses={styles.paymentBill} onClick={() => navigate(LinkPagesEnum.PaymentBill + "?billId=" + paymentBill.id)}>
                            {paymentBill.type == 0 &&
                            <div className={styles.textBlock}>
                                <div>Юр лицо:</div>
                                <div>{paymentBill.organization?.name}</div>
                            </div>}
                            {paymentBill.detailType == PaymentDetailType.ExpensesOnSalary &&
                            <div className={styles.textBlock}>
                                <div>ЗП сотруднику:</div>
                                <div>{paymentBill.salaryForEmployee?.user?.email}</div>
                            </div>}
                            {paymentBill.detailType == PaymentDetailType.ExpensesOnConsumables &&
                            <div className={styles.textBlock}>
                                <div>Покупка расходников</div>
                            </div>}
                            {paymentBill.detailType == PaymentDetailType.ExpensesOnIncidents &&
                            <div className={styles.textBlock}>
                                <div>Оплата инцидента</div>
                            </div>}
                            <div className={styles.textBlock}>
                                <div>Выплата:</div>
                                <div>{paymentBill.paymentBillDetails?.reduce((s, p) => s + p.paymentAmount!, 0)} ₽</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div>
                                    Статус:
                                </div>
                                <div style={{color: getColorByPaymentBillStatus(paymentBill.paymentStatus!)}}>
                                    {getPaymentBillStatus(paymentBill.paymentStatus!)}
                                </div>
                            </div>
                            <div className={styles.textBlock}>
                                <div>
                                    ID счёта:
                                </div>
                                <div>
                                    {paymentBill.id}
                                </div>
                            </div>
                            <FileExportIcon inlineClass={styles.fileExport} onClick={() => {}}/>
                        </Container>
                    )}
                </div>
                <Modal
                    isCloseCloseIcon
                    close={() => setIsModalAddingPaymentBillVisible(false)}
                    back={isFirstModalStage ? undefined : () => setIsFirstModalStage(true)}
                    isOpen={isModalAddingPaymentBillVisible}
                    >
                        {
                            isFirstModalStage 
                            ?
                            <div>
                                <Title inlineStyles={{margin: "0 auto 10px auto"}}>
                                    Выберите тип счёта
                                </Title>
                                <Button
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    text="Счет для клиента"
                                    onClick={() => {
                                        setNewBill({...newBill, detailType: PaymentDetailType.ProfitOther});
                                        setIsFirstModalStage(false);
                                    }}
                                    />
                                <Button
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    text="Выплата сотруднику"
                                    onClick={() => {
                                        setNewBill({...newBill, detailType: PaymentDetailType.ExpensesOnSalary});
                                        setIsFirstModalStage(false);
                                    }}
                                    />
                                <Button
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    text="Выплата за инцидент"
                                    onClick={() => {
                                        setNewBill({...newBill, detailType: PaymentDetailType.ExpensesOnIncidents});
                                        setIsFirstModalStage(false);
                                    }}
                                    />
                                <Button
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    text="Покупка расходников"
                                    onClick={() => {
                                        setNewBill({...newBill, detailType: PaymentDetailType.ExpensesOnConsumables});
                                        setIsFirstModalStage(false);
                                    }}
                                    />
                            </div>
                            :
                            <div style={{minHeight: 800, width: 340}}>
                                <Button
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    text="Создать счёт"
                                    onClick={handleCreateBill}
                                    />
                                <Select
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    options={warehouseOrganizations?.map(x => ({key: x.id!, value: x.name!}))}
                                    selectedOptionKey={selectedWarehouseOrganizationId}
                                    onChange={e => setSelectedWarehouseOrganizationId(e.key)}
                                    placeholder="Складская организация"
                                    isFetching={isWarehouseOrganizationsFetching}
                                    />
                                <Select
                                    inlineStyles={{margin: "0 auto 10px auto"}}
                                    options={warehouses?.map(x => ({key: x.id!, value: x.name!}))}
                                    selectedOptionKey={newBill.warehouseId}
                                    onChange={e => setNewBill({...newBill, warehouseId: e.key})}
                                    placeholder="Склад"
                                    isFetching={isWarehousesFetching}
                                    />
                                    {
                                        newBill.detailType == PaymentDetailType.ProfitOther
                                        &&
                                            <Select
                                                inlineStyles={{margin: "0 auto 10px auto"}}
                                                isFetching={isOrganizationsFetching}
                                                options={organizations?.map(x => ({key: x.id!, value: x.name!}))}
                                                selectedOptionKey={newBill.organizationId}
                                                onChange={e => setNewBill({...newBill, organizationId: e.key})}
                                                placeholder="Организация"
                                            />
                                    }
                                    {
                                        newBill.detailType == PaymentDetailType.ExpensesOnSalary
                                        &&
                                            <Select
                                                inlineStyles={{margin: "0 auto 10px auto"}}
                                                isFetching={isEmployeesFetching}
                                                options={employees?.map(x => ({key: x.id!, value: x.user?.email!}))}
                                                selectedOptionKey={newBill.salaryForEmployeeId}
                                                onChange={e => setNewBill({...newBill, salaryForEmployeeId: e.key})}
                                                placeholder="Сотрудники"
                                            />
                                    }
                                <TextArea
                                        inlineStyles={{minHeight: 100}}
                                        placeholder="Комментарий"
                                        value={newBill.note}
                                        onChange={e => setNewBill({...newBill, note: e.target.value})}
                                    />

                                <Title inlineStyles={{margin: "20px auto"}}>
                                    Создание подробности
                                </Title>

                                <Input
                                    inlineStyles={{marginBottom: 10}}
                                    placeholder="Наименование"
                                    value={detail.name}
                                    onChange={e => setDetail({...detail, name: e.target.value})}
                                    />
{/*                                     
                                <Input
                                    inlineStyles={{marginBottom: 10}}
                                    placeholder="Количество"
                                    value={detail.quantity}
                                    onChange={e => setDetail({...detail, quantity: e.target.value})}
                                    /> */}
                                    
                                <Input
                                    inlineStyles={{marginBottom: 10}}
                                    placeholder="Выплата"
                                    value={detail.paymentAmount?.toString()}
                                    type="number"
                                    onChange={e => setDetail({...detail, paymentAmount: Number(e.target.value)})}
                                    />

                                <Button
                                    inlineStyles={{margin: "0 auto 20px auto"}}
                                    text="Добавить подробность"
                                    onClick={() => {
                                        setNewBill({...newBill, createPaymentBillDetailModels: [...newBill.createPaymentBillDetailModels!, detail]})
                                        setDetail({...detail, name: "", quantity: "", paymentAmount: 0});
                                    }}
                                    />

                                <Title inlineStyles={{marginBottom: 20}}>
                                    Подробности
                                </Title>

                                {
                                    newBill.createPaymentBillDetailModels?.map((paymentBillDetail, index) => 
                                        <Container inlineStyles={{marginBottom: 10}} key={index} inlineClasses={styles.detail}>
                                            <div className={styles.detail__name}>Наименование:</div>
                                            <div style={{marginBottom: 10}} className={styles.detail__value}>{paymentBillDetail.name}</div>
                                            {/* <div className={styles.detail__name}>Количество:</div>
                                            <div style={{marginBottom: 10}} className={styles.detail__value}>{paymentBillDetail.quantity}</div> */}
                                            <div className={styles.detail__name}>Выплата:</div>
                                            <div className={styles.detail__value}>{paymentBillDetail.paymentAmount?.toString()}</div>
                                        </Container>
                                    )
                                }
                            </div>
                        }
                </Modal>
        </Layout>
    )
};

export {PaymentBillsPage};