import React, { useState } from "react";
import styles from "./Menu.module.scss";
import stylesm from "./index.m.module.scss";
import MenuItem from "./components/MenuItem/MenuItem";
import { menuItems } from "./consts";
import { useAppSelector } from "app/store/hooks/redux";
import UserProfile from "../Header/components/UserProfile/UserProfile";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { UserState } from "app/store/reducers/UserSlice";
import { BurgerIcon, Logo } from "shared/ui/icons/icons";

const Menu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    
    const user: UserState = useAppSelector(state => state.userReducer);
    const pageName = useAppSelector(state => state.pageNameReducer);
    const location = useLocation();

    if (window.outerWidth > 750) {
        return (
            <div className={styles.menuActive} style={ isMobileMenuOpen ? {marginLeft: "auto", width: "calc(100% - 370px)"} : {}}>
                <div className={styles.logo}>
                    <Logo/>
                </div>
                <div className={clsx(styles.menu__wrapper, isMobileMenuOpen ? styles.open : styles.close)}>
                    {/* <UserProfile className={styles.userProfile}/> */}
                    <div className={styles.menuActive__wrapper} onClick={e => e.stopPropagation()}>
                        {menuItems.map(item => 
                            (item.allowedFor?.some(r => user.roles?.includes(r)) && item.notAllowedFor?.some(r => !user.roles?.includes(r))) ?? true ?
                                <MenuItem menuItem={item} defaultOpen={item.path == location.pathname && location.pathname != "/"} key={item.name} {...item}/>
                                : null
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={clsx(stylesm.menu__wrapper, isMobileMenuOpen ? stylesm.open : stylesm.close)}>
                <img onClick={() => setIsMobileMenuOpen(false)} src="assets/svg/delete-white.svg" className={stylesm.deleteIcon}/>
                <img onClick={() => setIsMobileMenuOpen(false)} className={stylesm.profile} src="assets/images/default-profile.png" />
                <div className={stylesm.menuActive__wrapper} onClick={e => e.stopPropagation()}>
                    {menuItems.map(item => 
                    item.allowedFor?.some(r => user.roles?.includes(r)) ?? true ?
                            <MenuItem menuItem={item} defaultOpen={item.path == location.pathname && location.pathname != "/"} key={item.name} {...item}/>
                            : null
                    )}
                </div>
            </div>
            <div className={stylesm.menuActive} style={ isMobileMenuOpen ? {marginLeft: "auto", width: "calc(100% - 370px)"} : {}}>
                {!isMobileMenuOpen && <BurgerIcon onClick={() => setIsMobileMenuOpen(true)} inlineClass={stylesm.burger}/> }
                <div className={stylesm.pageName}>
                    {pageName.pageName}
                </div>
            </div>
        </>
    )

};

export default Menu;