import { ProductCreateModel, useGetOrganizationsQuery, usePostProductsImageMutation, usePostProductsMutation } from "api"
import { useAppSelector } from "app/store/hooks/redux"
import { useState } from "react"
import { Input } from "shared/ui/Input"
import { MiniTitle } from "shared/ui/MiniTitle"
import { Select } from "shared/ui/Select"
import Layout from "widgets/Layout/Layout"
import styles from "./index.module.scss";
import { ImageSelector } from "features/ImageSelector"
import { NavigateButton } from "shared/ui/NavigateButton"
import { LinkPagesEnum } from "app/store/enums/linkPages"
import { Button } from "shared/ui/Button"
import { useNavigate } from "react-router-dom"

const AddingProductPage = () => {
    const {organizationId} = useAppSelector(state => state.userReducer);
    const {data: organizations} = useGetOrganizationsQuery();
    const [newProduct, setNewProduct] = useState<ProductCreateModel>({
        organizationId: organizationId?? undefined,
        length: 0,
        height: 0,
        width: 0,
        weight: 0,
    });
    
    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>("");

    const [addProduct] = usePostProductsMutation();

    const [createImage] = usePostProductsImageMutation();

    const navigate = useNavigate();

    const addProductHandler = async () => {
        try {
            const productId = await addProduct({
                productCreateModel: newProduct
            }).unwrap();

            await createImage({body: {
                ProductId: productId,
                File: currentImage,
            }})

            setNewProduct({});
            navigate(LinkPagesEnum.Products);
            
        } catch (err) {
            // Handle error
        }
    };
    
    return (
        <Layout>
            <div>
                <div style={{display: "flex", marginBottom: 20}}>
                    <NavigateButton
                        to={LinkPagesEnum.Products}
                        text="Назад"
                        inlineStyles={{marginRight: 10}}
                        />
                    <Button
                        text="Создать"
                        onClick={addProductHandler}
                        />
                </div>
                <div>
                    <div className={styles.fieldsContainer}>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Информация о товаре
                            </MiniTitle>
                            <Input placeholder="Название" value={newProduct.name} onChange={e => setNewProduct({...newProduct, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Артикул" value={newProduct.article} onChange={e => setNewProduct({...newProduct, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Цвет" value={newProduct.color} onChange={e => setNewProduct({...newProduct, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            {/* <Input placeholder="Цена обслуживания" value={newProduct.serviceProductPrice?.toString()} onChange={e => setNewProduct({...newProduct, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/> */}
                            <Select placeholder="Организация" options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} selectedOptionKey={newProduct.organizationId} onChange={val => setNewProduct({...newProduct, organizationId: val.key})} classNames={styles.modal__field}/>
                        </div>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Габариты и вес
                            </MiniTitle>
                            <Input type="number" placeholder="Высота упаковки, см" value={newProduct.height?.toString()} onChange={e => setNewProduct({...newProduct, height: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Длина упаковки, см" value={newProduct.length?.toString()} onChange={e => setNewProduct({...newProduct, length: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Ширина упаковки, см" value={newProduct.width?.toString()} onChange={e => setNewProduct({...newProduct, width: Number(e.target.value) })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Объём см3" value={(newProduct.height! * newProduct.length! * newProduct.width!).toString()} onChange={e => {}} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Вес товара кг" value={newProduct.weight?.toString()} onChange={e => setNewProduct({...newProduct, weight: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                        </div>
                    </div>
                    <div className={styles.fieldsContainer} style={{marginTop: 20}}>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Штрих - коды
                            </MiniTitle>
                            <Input placeholder="Штрихкод" value={newProduct.barcode} onChange={e => setNewProduct({...newProduct, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Заводской штрихкод" value={newProduct.factoryBarcode} onChange={e => setNewProduct({...newProduct,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            {/* <div>
                                <Input
                                    placeholder=""
                                    value={}
                                    onChange={() => }
                                    />
                            </div> */}
                        </div>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Изображение
                            </MiniTitle>
                            <ImageSelector
                                inlineStyles={{width: 400, height: 250}}
                                previewImage={previewImage}
                                setCurrentImage={setCurrentImage}
                                setPreviewImage={setPreviewImage}
                                />                                    
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export {
    AddingProductPage
}