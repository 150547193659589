import Layout from "widgets/Layout/Layout";
import * as Highcharts from 'highcharts';
import {HighchartsReact} from 'highcharts-react-official';
import { useEffect, useRef, useState } from "react";
import HC_exporting from 'highcharts/modules/exporting'
import { useGetAnalyticsMoneyDateQuery, useGetAnalyticsMoneyQuery, useGetAnalyticsProductsQuery } from "api";
import { getLocalDate } from "functions/time";
import { OptionType, Select } from "shared/ui/Select";
import { Container } from "shared/ui/Container";

// init the module
HC_exporting(Highcharts)


const moneySelectOptions: OptionType[] = [
    {value: "Всё", key: 0},
    {value: "Доход", key: 1},
    {value: "Расход", key: 2},
]

const ChartsPage = () => {
    const [properties, setProperties] = useState<Highcharts.Options>(
        {
            title: {
                text: 'Количество обработанных товаров',
                align: 'left'
            },
            yAxis: {
                title: {
                    text: 'Колиство товара'
                }
            },
        
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
        
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },
        
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        });

        const [moneyDateProperties, setMoneyDateProperties] = useState<Highcharts.Options>(
            {
                title: {
                    text: 'Финансовая динамика',
                },
                // title: {
                //     text: '',
                //     align: 'left'
                // },
                // yAxis: {
                //     title: {
                //         text: ''
                //     }
                // },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
            
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            });
    
        const [moneyVisibleType, setMoneyVisibleType] = useState(0);



    const [moneyProperties, setMoneyProperties] = useState<Highcharts.Options>(
        {
            chart: {
                type: "pie",
                style: {
                    height: 300,
                    width: 300
                }
            },
            title: {
                text: '<div>Финансовые корреляции</div>',
            },
            tooltip: {
                valueSuffix: 'Р'
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: [
                    //     {
                    //     enabled: true,
                    //     // @ts-ignore: Unreachable code error
                    //     distance: 20
                    // }, 
                    // {
                    //         enabled: true,
                    //         // @ts-ignore: Unreachable code error
                    //         distance: -20,
                    //     }, 
                    {
                        enabled: true,
                        // @ts-ignore: Unreachable code error
                        distance: -40,
                        format: '<b style="font-size: 9px">{point.name}</b><br>{point.percentage:.1f} %',
                        style: {
                            fontSize: '0.9em',
                            textOutline: 'none',
                            opacity: 0.7
                        },
                    }]
                }
            },
        });

    const {data: productsData } = useGetAnalyticsProductsQuery();
    const {data: moneyData } = useGetAnalyticsMoneyQuery();
    const {data: moneyDateData } = useGetAnalyticsMoneyDateQuery();

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const chartMoneyComponentRef = useRef<HighchartsReact.RefObject>(null);
    const chartMoneyDateComponentRef = useRef<HighchartsReact.RefObject>(null);
    

    useEffect(() => {
        if (moneyDateData != null) {
            if (moneyVisibleType == 0) {
                setMoneyDateProperties({...moneyDateProperties, 
                    series: moneyDateData.all?.map(x => ({
                        type: "line",
                        name: x.name!,
                        data: x.details?.map(x => x.number!)
                    }))
                    , 
                    xAxis: {
                        categories: moneyDateData.dates?.map(x => getLocalDate(x)),
                    },
                });
            }
            else if (moneyVisibleType == 1) {
                setMoneyDateProperties({...moneyDateProperties, 
                    series: moneyDateData.profit?.map(x => ({
                        type: "line",
                        name: x.name!,
                        data: x.details?.map(x => x.number!)
                    }))
                    , 
                    xAxis: {
                        categories: moneyDateData.dates?.map(x => getLocalDate(x)),
                    }
                });
            }
            else {
                setMoneyDateProperties({...moneyDateProperties, 
                    series: moneyDateData.expense?.map(x => ({
                        type: "line",
                        name: x.name!,
                        data: x.details?.map(x => x.number!)
                    }))
                    , 
                    xAxis: {
                        categories: moneyDateData.dates?.map(x => getLocalDate(x)),
                    }
                });
            }
        }
    }, [moneyDateData, moneyVisibleType])


    useEffect(() => {
        if (moneyData != null) {
            if (moneyVisibleType == 0) {
                setMoneyProperties({...moneyProperties, series: [
                    {
                        name: '',
                        type: "pie",
                        data: moneyData.all?.details?.map(x => ({
                            y: x.number!,
                            name: x.name!
                        }))
                    },
                ],
                    
                title: {
                    text: '<div>Финансовые корреляции</div>'
                            + '<br/>'
                            + 'Прибыль: '
                            + moneyData.all?.sum
                            + "Р",
                },
                });
            }
            else if (moneyVisibleType == 1) {
                setMoneyProperties({...moneyProperties, series: [
                    {
                        name: '',
                        type: "pie",
                        data: moneyData.profit?.details?.map(x => ({
                            y: x.number!,
                            name: x.name!
                        }))
                    },
                ],
                    
                title: {
                    text: '<div>Финансовые корреляции</div>'
                            + '<br/>'
                            + 'Доход: '
                            + moneyData.profit?.sum
                            + "Р",
                },
                });
            }
            else {
                setMoneyProperties({...moneyProperties, series: [
                    {
                        name: '',
                        type: "pie",
                        data: moneyData.expense?.details?.map(x => ({
                            y: x.number!,
                            name: x.name!
                        }))
                    },
                ],
                title: {
                    text: '<div>Финансовые корреляции</div>'
                            + '<br/>'
                            + 'Расход: '
                            + moneyData.expense?.sum
                            + "Р",
                },
                });
            }
        }
    }, [moneyData, moneyVisibleType])

    useEffect(() => {
        if (productsData != null) {
            
            setProperties({...properties, series: [
                {
                    type: "line",
                    name: "принятые",
                    data: productsData.acceptance?.map(x => x.quantity!)
                },
                {
                    type: "line",
                    name: "отправленные",
                    data: productsData.shipment?.map(x => x.quantity!)
                },
                {
                    type: "line",
                    name: "упакованные",
                    data: productsData.package?.map(x => x.quantity!)
                },
                {
                    type: "line",
                    name: "всё",
                    data: productsData.all?.map(x => x.quantity!)
                },
            ], xAxis: {
                categories: productsData.all!.map(x => getLocalDate(x.dateTime!)),
            }
            });
        }
    }, [productsData])

    return (
        <Layout>
            <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "28%", height: 300}}>   
                        <Select
                            inlineStyles={{marginBottom: 10}}
                            options={moneySelectOptions}
                            selectedOptionKey={moneyVisibleType}
                            onChange={e => setMoneyVisibleType(e.key)}
                            placeholder="Тип"
                            />    
                        <Container>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={moneyProperties}
                            ref={chartMoneyComponentRef}
                        />
                        </Container>
                    </div>            
                    <Container inlineStyles={{width: '70%'}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={moneyDateProperties}
                            ref={chartMoneyDateComponentRef}
                            />
                    </Container>
                </div>
                <Container inlineStyles={{marginTop: 20, marginBottom: 20}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={properties}
                        ref={chartComponentRef}
                    />
                </Container>
            </div>
        </Layout>
    );
};

export {
    ChartsPage
}