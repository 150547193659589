import React, { useEffect, useState } from "react";
import { tableHeadName } from "pages/Products/consts";
import { IS_PC } from "app/store/consts";
import styles from "./index.module.scss";
import { Sort } from "shared/ui/Sort";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { SearchInput } from "shared/ui/SearchInput";
import {Button} from "shared/ui/Button";
import Layout from "widgets/Layout/Layout";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { ProductCreateModel, ProductDetail, ProductOutputModel, useGetProductsByIdDetailsQuery, useGetProductsQuery, useGetUsersUserOrganizationsQuery } from "api";
import { OptionType } from "shared/ui/Select";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { IsArrayIsNotEmpty } from "functions/array";
import { ProductModal } from "features/Modals/Product";
import { ActionsSelector } from "features/ActionsSelector";
import { getImageUrlByStrings } from "functions/image";
import { NavigateButton } from "shared/ui/NavigateButton";
import { useAppSelector } from "app/store/hooks/redux";

type Props = {
};

const options: OptionType[] = [
    {key: 1, value: "Штрихкод"},
    {key: 2, value: "Наименование"},
    {key: 3, value: "Цвет"},
    {key: 4, value: "Размер"},
    {key: 5, value: "Цена услуги"},
    {key: 6, value: "Количество"},
    {key: 7, value: "Юр лицо"}
];

const ProductsPage = ({}:Props) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);
    const [selectedProductForRedact, setSelectedProductForRedact] = useState<ProductOutputModel>({});
    
    const {data: products, refetch, isFetching: isProductsFetching} = useGetProductsQuery({});
    const {isClient} = useAppSelector(state => state.userReducer);

    const filteredData = products?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.barcode, key: 1},
        {value: el.name, key: 2},
        {value: el.color, key: 3},
        {value: el.color, key: 4},
        {value: el.serviceProductPrice, key: 5},
        {value: el.quantity, key: 6},
        {value: el.organizationName, key: 7}
    ]));
    
    const [selectedOptionKey, setSelectedOption] = useState(6);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.barcode, value1: el1.barcode, key: 1},
        {value2: el2.name,  value1: el1.name, key: 2},
        {value2: el2.color, value1: el1.color, key: 3},
        {value2: el2.color, value1: el1.color, key: 4},
        {value2: el2.serviceProductPrice, value1: el1.serviceProductPrice, key: 5},
        {value2: el2.quantity, value1: el1.quantity, key: 6},
        {value2: el2.organizationName, value1: el1.organizationName, key: 7}
    ], selectedOptionKey, isDesc));
   
    const [createdProduct, setCreatedProduct] = useState<ProductCreateModel>({});
    const {data: organizations} = useGetUsersUserOrganizationsQuery();

    useEffect(()=> {
        if (IsArrayIsNotEmpty(organizations)) 
        {
            setCreatedProduct({...createdProduct, organizationId: organizations![0].id});
        }
    }, [organizations]);

    // const [importProducts] = usePostProductsExcelMutation();
    const [isProductVariablesVisible, setIsProductVariablesVisible] = useState(false);
    const {data: productDetails, isFetching: isProductDetailsFetching} = useGetProductsByIdDetailsQuery({id: selectedProductForRedact.id!}, {skip: selectedProductForRedact.id == null});
    
    const [selectedDetailForVisibleCells, setSelectedDetailForVisibleCells] = useState<ProductDetail | null>(null);
    const [selectedProductId, setSelectedProductsItem] = useState<number | null>();
    const [event, setEvent] = useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);

    if (IS_PC) {
        return (
            <Layout>
                    <div style={{display: 'flex', justifyContent:'space-between', marginBottom: 20}}>
                        <div style={{display: "flex"}}>
                            <Button
                                inlineStyles={{marginRight: 10}}
                                text="Обновить"
                                onClick={refetch}
                                />
                            {/* <ImportButton
                                addFile={file => importProducts({body: {File: file}})}
                                text="Импорт"
                                inlineStyles={{marginRight: 10}}
                                /> */}
                            {isClient && <>
                                <NavigateButton inlineStyles={{marginRight: 10}}  text="Добавить товар" to={LinkPagesEnum.AddingProduct}/>
                                <Button  text="Добавить комплект" onClick={() => navigate(LinkPagesEnum.AddingKit)}/>
                                </>}
                            {/* <Button text="Экспорт" onClick={() => window.open("http://185.211.170.95/api/media/productsTemplate.xlsx","_blank")}/> */}
                            {/* <Button text="Скачать шаблон" onClick={() => window.open("http://185.211.170.95/api/media/productsTemplate.xlsx","_blank")}/> */}
                        </div>
                        <div style={{display: 'flex'}}>
                            <SearchInput 
                                value={searchText} 
                                onChange={setSearchText}
                                inlineStyles={{marginRight: 10}}
                                classNames={styles.searchInput}
                                />
                            <MultipleSelect 
                                text="Параметры"
                                options={options} 
                                selectedOptionKeys={selectedOptions} 
                                setSelectedOptions={setSelectedOptions}
                                inlineStyles={{marginRight: 10, width: 135}}
                                />
                            <Sort 
                                options={options}
                                selectedOptionKey={selectedOptionKey}
                                setSelectedOption={setSelectedOption}
                                setIsDesc={setIsDesc}
                                isDesc={isDesc}
                                selectClassNames={styles.select}
                                />
                        </div>
                    </div>
                        <Table columns={tableHeadName} isFetching={isProductsFetching}>
                            {sortedData?.map(el => (
                                    <TableItem hover inlineStyles={{marginBottom: 10}} onClick={(e) => {
                                            setEvent(e); 
                                            setSelectedProductsItem(el.id!)
                                        }}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={getImageUrlByStrings(el.images)} alt={el.name!} /> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}>{el.name}</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}> {el.barcode} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{el.organizationName} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}>{el.quantity ? el.quantity : 0} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}>{el.color} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}>{el.capacity?.toFixed(2)}см3</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[7].width}>{el.serviceProductPrice ? el.serviceProductPrice : 0} </TableItemContainer> 
                                        </>
                                    </TableItem>
                            ))}
                        </Table>
                        <ActionsSelector
                            event={event!}
                            close={() => setSelectedProductsItem(null)}
                            isOpen={selectedProductId != null}
                            actions={[
                                {
                                    name: "Посмотреть подробности", 
                                    func: () => {
                                        const product = products?.find(x => x.id == selectedProductId);

                                        setIsProductVariablesVisible(true);
                                        setSelectedProductForRedact(product!);
                                    }
                                },
                                {
                                    name: "Редактировать", 
                                    func: () => {
                                        navigate(LinkPagesEnum.RedactProduct + "?productId=" + selectedProductId)
                                }},
                                {
                                    name: "Экспортировать", 
                                    func: () => {
                                    // const product = products?.find(x => x.id == selectedProductId);

                                    // setIsRedactModalOpen(true);
                                    // setSelectedProductForRedact(product!);
                                }},
                            ]}
                            />
                        <ProductModal
                            isLoader={isProductDetailsFetching}
                            close={() => setIsProductVariablesVisible(false)}
                            cellsBack={() => setSelectedDetailForVisibleCells(null)}
                            isOpen={isProductVariablesVisible}
                            details={selectedProductForRedact.id != null ? productDetails : null}
                            onDetailClick={(el) => setSelectedDetailForVisibleCells(el)}
                            cells={selectedDetailForVisibleCells?.productDetailCells}
                            />
            </Layout>
        );
    }
    return (
        <div></div>
    );
    
};

export {ProductsPage};