import React, { useEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { ProductDetail, ProductOutputModel, RedactBillModel, useGetProductsByIdDetailsQuery, useGetProductsQuery, useGetUsersUserOrganizationsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsBillMutation } from "api";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import {Modal} from "shared/ui/Modal";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { SearchInput } from "shared/ui/SearchInput";
import img from "shared/assets/images/box.png";
import { NavigateButton } from "shared/ui/NavigateButton";
import { NotMore } from "functions/math";
import { ProductModal } from "features/Modals/Product";
import { useAppSelector } from "app/store/hooks/redux";
import { MiniTitle } from "shared/ui/MiniTitle";
import { getImageUrlByStrings } from "functions/image";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Наименование", width: "20%"},
    {name: "Штрих-код", width: "10%"},
    {name: "Цвет", width: "5%"},
    {name: "Объём", width: "10%"},
    {name: "Особенности", width: "10%"},
    {name: "Остаток", width: "15%"},
    {name: "Количество", width: "15%"},
]

const transporters: OptionType[] = [
    {value: "Склад", key: 1},
    {value: "Самостоятельно", key: 2},
    {value: "Транспортная компания", key: 3},
];

const AddingShipmentBillPage = ({}: Props) => {
    const {warehouseId, organizationId, warehouseOrganizationId} = useAppSelector(state => state.userReducer);
    
    const navigate = useNavigate();
    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [quantities, setQuantities] = useState<{key: string, quantity: number}[]>([]);

    const [newBill, setNewBill] = useState<RedactBillModel>({
        realizeDate: (new Date()).toISOString(),
        carId: 1,
        type: 1,
        transporter: transporters[0].value,
        warehouseId: warehouseId ?? undefined,
        organizationId: organizationId?? undefined,
    });
    
    const [addAcceptanceBillQuery] = usePostBillsBillMutation();
    const {data: organizations} = useGetUsersUserOrganizationsQuery();
    const {data: receivedProductsByUser, isFetching: isProductsFetching} = useGetProductsQuery({warehouseId: newBill.warehouseId!, organizationId: newBill.organizationId!}, {skip: newBill.warehouseId == null && newBill.organizationId == null});

    useEffect(()=> {
        if (organizations != null && organizations.length == 1) 
        {
            setNewBill({...newBill, organizationId: organizations[0].id!});
        }
    }, [organizations]);

    const addAcceptanceBill = async () => {
        try {
            await addAcceptanceBillQuery({redactBillModel: {...newBill, products: selectedDetails.map(detail => {
                const quantity = quantities.find(quantity => quantity.key == detail?.key)?.quantity;
                return {id: detail.productId, packageTypeId: detail.packageTypeId, quantity: quantity};
            })}}).unwrap();

            navigate(LinkPagesEnum.ShipmentBills);
        }
        catch (error) {

        }
    };

    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState(warehouseOrganizationId);
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});
    
    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [selectedProductIdForVisibleDetails, setSelectedProductsIdForVisibleDetails] = useState<number | null>(null);
    const {data: productDetails, isFetching: isProductDetailsFetching} = useGetProductsByIdDetailsQuery({id: selectedProductIdForVisibleDetails!, warehouseId: newBill.warehouseId}, {skip: selectedProductIdForVisibleDetails == null || newBill.warehouseId == null});
    const [selectedDetails, setSelectedDetails] = useState<ProductDetail[]>([]);

    return (
        <Layout>
                <>
                    <div>
                        <div className={styles.header}>
                            <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.ShipmentBills}/>
                            <Button text="Создать" onClick={addAcceptanceBill}/>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Информация
                                </MiniTitle>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                        <Select 
                                            placeholder="Складская организация"
                                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                            selectedOptionKey={selectedWarehouseOrganizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                            isFetching={isWarehousesFetching}
                                            placeholder="Склад"
                                            options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, warehouseId: val.key})}
                                            selectedOptionKey={newBill.warehouseId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            placeholder="Организация"
                                            options={organizations?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, organizationId: val.key!})}
                                            selectedOptionKey={newBill.organizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={newBill.externalBillId}
                                            onChange={e => setNewBill({...newBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setNewBill({...newBill, note: e.target.value})}
                                        value={newBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Доставка
                                </MiniTitle>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={newBill.sender}
                                        onChange={e => setNewBill({...newBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setNewBill({...newBill, transporter: val.value})}
                                        selectedOptionKey={transporters.find(x => x.value == newBill.transporter)?.key}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={newBill.realizeDate!.split('T')[0]}
                                        onChange={e => setNewBill({...newBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={newBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setNewBill({...newBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={newBill.palletsCount?.toString()}
                                        onChange={e => setNewBill({...newBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={newBill.deliveryNote}
                                        onChange={e => setNewBill({...newBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>
                            <Table columns={columns}>
                                {
                                    selectedDetails!.map(detail => {
                                        const product = selectedProducts?.find(p => p.id == detail?.productId);

                                        return (<TableItem inlineStyles={{marginBottom: 10}}>
                                            <>
                                                <TableItemContainer width={columns[0].width}><img src={getImageUrlByStrings(product?.images)} className={styles.img}/></TableItemContainer>
                                                <TableItemContainer width={columns[1].width}><div className={styles.text}>{product?.name}</div></TableItemContainer>
                                                <TableItemContainer width={columns[2].width}><div className={styles.text}>{product?.barcode}</div></TableItemContainer>
                                                <TableItemContainer width={columns[3].width}><div className={styles.text}>{product?.color}</div></TableItemContainer>
                                                <TableItemContainer width={columns[4].width}><div className={styles.text}>{product?.capacity}</div></TableItemContainer>
                                                <TableItemContainer width={columns[5].width}><div className={styles.text}>
                                                    <div>
                                                        Организация: {detail.organizationName}
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        Склад: {detail.warehouseName}
                                                    </div>
                                                    {detail.consumables != null &&
                                                        <>
                                                        <br/>
                                                        <div>
                                                            Расходники:
                                                            {detail.consumables?.map(c => <>
                                                                <div>{c.name}/{c.quantity} ед.</div>
                                                            </>)}
                                                        </div>
                                                        </>
                                                    }
                                                    <br/>
                                                    <div>Количество: {detail.quantity}</div>
                                                </div></TableItemContainer>
                                                <TableItemContainer width={columns[6].width}><div className={styles.text}>{detail?.quantity}</div></TableItemContainer>
                                                <TableItemContainer width={columns[7].width}>
                                                    <Input 
                                                        type="number"
                                                        inlineStylesContainer={{width: 100}}
                                                        onChange={e => 
                                                            setQuantities(prevState =>
                                                                {   
                                                                    if (prevState.find(pv => pv.key == detail.key)) {
                                                                        return prevState.map(pv => pv.key == detail.key!
                                                                            ? {...pv, quantity: NotMore(Number(e.target.value), detail?.quantity)} 
                                                                            : pv
                                                                            )
                                                                    }
                                                                    return [...prevState, {key: detail.key!, quantity: NotMore(Number(e.target.value), detail?.quantity)}]
                                                                } 
                                                                )
                                                            } 
                                                        value={quantities!.find(q => q.key == detail.key!)?.quantity.toString()}
                                                        />
                                                </TableItemContainer>
                                            </>
                                        </TableItem>)})
                                }
                            </Table>
                        </div>
                    </div>
                    <ProductModal
                        isLoader={isProductsFetching || isProductDetailsFetching}
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        detailsBack={() => setSelectedProductsIdForVisibleDetails(null)}
                        products={receivedProductsByUser}
                        selectedProducts={selectedProducts}
                        onProductClick={(el) => { 
                            setSelectedProductsIdForVisibleDetails(el.id!);
                        }}
                        details={selectedProductIdForVisibleDetails != null ? productDetails : null}
                        selectedDetails={selectedDetails}
                        onDetailClick={(el) => { 
                            const product = receivedProductsByUser!.find(p => p.id == el.productId)!;

                            if (selectedDetails?.includes(el))
                            {
                                setSelectedDetails(prevState => prevState.filter(x => x.key != el.key!));

                                var idxForDelete = selectedProducts.indexOf(product);
                                if (idxForDelete != -1) {
                                    selectedProducts.splice(idxForDelete, 1);
                                }
                            }
                            else {
                                setSelectedProducts(prevState => [...prevState, product]);
                                setSelectedDetails(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    )
};

export {AddingShipmentBillPage};