import Layout from "widgets/Layout/Layout";
import React, { useEffect, useRef, useState } from "react";
import { tableHeadName } from "pages/Cells/consts";
import {Modal} from "shared/ui/Modal";
import styles from "./index.module.scss";
import { OpenListIcon } from "shared/ui/icons/icons";
import {Button} from "shared/ui/Button";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { useGetCellsQuery, useGetProductsQuery, useGetWarehouseListByUserQuery, useGetWarehouseOrganizationsByUserQuery, usePostCellsMutation, usePostWarehouseCreateMutation, usePostWarehouseCreateOrganizationMutation } from "api";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { getPercentColor } from "functions/color";
import Title from "shared/ui/Title/Title";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { IsArrayIsNotEmpty } from "functions/array";
import { useAppSelector } from "app/store/hooks/redux";
import { getNameByCellType } from "functions/cellType";
import Barcode from "react-barcode";
import {useReactToPrint} from "react-to-print";
import { ProductModal } from "features/Modals/Product";

const cellTypes: OptionType[] = [
    {value: "Отгрузка", key: 1},
    {value: "Приёмка", key: 2},
    {value: "Упаковка", key: 3},
    {value: "Хранение", key: 4},
    {value: "Другое", key: 5},
];

const options: OptionType[] = [
    {value: "Ячейки", key: 1},
    {value: "Заполненность", key: 2},
    {value: "Код (ID)", key: 3},
    {value: "Имя", key: 4},
    {value: "Склад", key: 5},
];

const CellsPage = () => {
    const [createCell, {isLoading: isCreateCellLoading}] = usePostCellsMutation();
    const {data: warehouses} = useGetWarehouseListByUserQuery();
    const {data: organizations } = useGetWarehouseOrganizationsByUserQuery();
    const createCellHandler = async () => {
        try {
            if (selectedWarehouseId == null) {
                alert("Выберите склад для ячейки")
                
                return;
            }
            await createCell({
                createCellRequest: {
                    type: cellType,
                    length: Number(cellLength),
                    width: Number(cellWidth),
                    height: Number(cellHeight),
                    warehouseId: selectedWarehouseId,
                    name: cellName
                }
            }).unwrap();

            setCellHeight("");
            setCellWidth("");
            setCellLength("");
            setCellName("");
            setIsCellModalVisible(false);
        }
        catch (err) {

        }
    }
    const [cellName, setCellName] = useState("");
    const [cellType, setCellType] = useState<CellTypeEnum>(0);
    const [cellLength, setCellLength] = useState("");
    const [cellWidth, setCellWidth] = useState("");
    const [cellHeight, setCellHeight] = useState("");
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<number | null>();
    
    useEffect(()=>{
        if (IsArrayIsNotEmpty(warehouses)) {
            setSelectedWarehouseId(warehouses![0]!.id!);
        }
    },[warehouses]);
    const [createOrganization, {isLoading: isCreateOrgLoading}] = usePostWarehouseCreateOrganizationMutation();
    const [organizationName, setOrganizationName] = useState("");
    const createOrganizationHandler = async () => {
        try {
            await createOrganization({
                organizationName: organizationName,
            }).unwrap();

            setIsWarehouseOrganizationModalVisible(false);
        }
        catch (err) {

        }
    }
    
    const [createWarehouse, {isLoading: isCreateWarehouseLoading}] = usePostWarehouseCreateMutation();
    const createWarehouseHandler = async () => {
        try {
            await createWarehouse({
                createWarehouseInputModel: {
                    name: warehouseName,
                    address: warehouseAdress,
                    organizationId: selectedOrganizationId,
                }
            }).unwrap();

            setIsWarehouseModalVisible(false);
        }
        catch (err) {

        }
    }
    useEffect(()=>{
        if (IsArrayIsNotEmpty(organizations)) {
            setSelectedOrganizationId(organizations![0]!.id!);
        }
    },[organizations]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(0);
    const [warehouseName, setWarehouseName] = useState("");
    const [warehouseAdress, setWarehouseAdress] = useState("");

    const [isCellModalVisible, setIsCellModalVisible] = useState(false);
    const [isWarehouseModalVisible, setIsWarehouseModalVisible] = useState(false);
    const [isWarehouseOrganizationModalVisible, setIsWarehouseOrganizationModalVisible] = useState(false);

    const [targetCellId, setTargetCellId] = useState<number | null>(null);
    const {data: products, isFetching: isProductsFetching} = useGetProductsQuery({cellId: targetCellId!}, {skip: targetCellId == null});
    const {data: cells, refetch, isFetching: isCellsFetching} = useGetCellsQuery({});

    const [isOpen, setIsOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);
    const [searchText, setSearchText] = useState("");

    const filteredData = cells?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.place, key: 1},
        {value: el.occupancyPercent, key: 2},
        {value: el.code, key: 3},
        {value: el.name, key: 4},
        {value: el.warehouseName, key: 5},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(2);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.place, value1: el1.place, key: 1},
        {value2: el2.name,  value1: el1.name, key: 2},
        {value2: el2.code, value1: el1.code, key: 3},
        {value2: el2.name, value1: el1.name, key: 4},
        {value2: el2.warehouseName, value1: el1.warehouseName, key: 5},
    ], selectedOption, isDesc));
   
    const {isWarehouseOwner} = useAppSelector(state => state.userReducer);
    const [isBarcodeModalVisible, setIsBarcodeModalVisible] = useState(false);
    
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    
    return (
        <Layout>
        <>
            <div style={{display: "flex", marginBottom: "20px", justifyContent: 'space-between'}}>
                <div style={{display: "flex"}}>
                    <Button
                        inlineStyles={{marginRight: 10}}
                        text="Обновить"
                        onClick={refetch}
                        />
                    <Button
                        inlineStyles={{marginRight: 10}}
                        text="Создать ячейку"
                        onClick={() => setIsCellModalVisible(true)}
                        />
                    {isWarehouseOwner && <Button
                        inlineStyles={{marginRight: 10}}
                        text="Создать склад"
                        onClick={() => setIsWarehouseModalVisible(true)}
                        />}
                    {isWarehouseOwner && <Button
                        inlineStyles={{marginRight: 10}}
                        text="Создать организацию"
                        onClick={() => setIsWarehouseOrganizationModalVisible(true)}
                        />}
                    {/* <ImportButton inlineStyles={{marginRight: 4}} text="Импорт" addFile={addFile}/> */}
                    {/* <Button text="Скачать шаблон" onClick={() => window.open(BASE_URL + "/media/productsTemplate.xlsx","_blank")}/> */}
                </div>
                <div style={{display: "flex"}}>
                    <SearchInput
                        classNames={styles.searchInput}
                        value={searchText}
                        onChange={setSearchText}
                        inlineStyles={{marginRight: 10}}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptionKeys={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10, width: 135}}
                        />
                    <Sort 
                        options={options}
                        selectedOptionKey={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        selectClassNames={styles.select}
                        />
                </div>
            </div>
            <Table columns={tableHeadName} isFetching={isCellsFetching}>
                {sortedData?.map(el => (
                    <TableItem inlineStyles={{marginBottom: 10}}>
                        <>
                            <TableItemContainer width={tableHeadName[0].width}><div className={styles.name}>{el.place} </div></TableItemContainer>
                            <TableItemContainer width={tableHeadName[1].width}>{getNameByCellType(el.cellType)}</TableItemContainer>
                            <TableItemContainer width={tableHeadName[2].width}><div style={{color: getPercentColor(el.occupancyPercent!)}}>{el.occupancyPercent?.toFixed(3)}%</div></TableItemContainer>
                            <TableItemContainer width={tableHeadName[3].width}>{el.warehouseName}</TableItemContainer>
                            <TableItemContainer width={tableHeadName[4].width}> <OpenListIcon onClick={() => {setIsOpen(true);setTargetCellId(el.id!);}} inlineClass={styles.openListIcon}/></TableItemContainer>
                            <TableItemContainer width={tableHeadName[5].width}><Button text="Смотреть" onClick={() => {setTargetCellId(el.id!);setIsBarcodeModalVisible(true)}}/></TableItemContainer>
                        </>
                    </TableItem>
                ))}
            </Table>

                {/* <div className={styles.mobile}>
                    { sortedData?.map(el => (
                        <div className={styles.item_m}>
                            <div className={styles.bold} style={{marginBottom: 5, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div>
                                    Ячейка:
                                </div>
                                <div 
                                    style={{
                                        color: "#C5A2E0", 
                                        fontWeight: 500, 
                                        padding: "5px 10px", 
                                        backgroundColor: "#EAE4EE",
                                        borderRadius: 5,
                                        marginTop: 5
                                    }}
                                    >
                                    {el.place}
                                </div>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Заполненность:
                                <span style={{color: "#C5A2E0", fontWeight: 500, marginLeft: 3}}>
                                    {el.occupancyPercent}%
                                </span>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Код (ID): 
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.code}
                                </span>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Имя: 
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.name}
                                </span>
                            </div>
                            <div className={styles.bold} style={{marginBottom: 5}}>
                                Склад:
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.warehouse.name}
                                </span>
                            </div>
                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <div className={styles.bold} style={{marginBottom: 5}}>
                                    Этикетка:
                                </div>
                                <div className={styles.button}>
                                    Печать
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setIsOpen(true);
                                    handlerClick(el.id);
                                }}
                                style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}}
                                >
                                <OpenListIcon/>
                            </div>
                        </div>
                    ))
                    }
                </div> */}

                {/* <TableNavbar 
                    text={"Ячейки"} 
                    buttonsNavbar={buttonsNavbar}
                    updateButton={updateButton}
                    onSearch={()=>{}}
                    searchValue=""
                /> */}
                {/* <ContentCard header={tableHeadName}>
                    {storagePlaces.map(item => (
                        <StorageItem 
                            key={item.name}
                            setIsOpenModal={setIsOpen}
                            item={item}
                            onItemClick={handlerClick}
                        />
                    ))}
                </ContentCard> */}
                <Modal 
                    isOpen={isBarcodeModalVisible} 
                    isCloseCloseIcon
                    classNames={styles.modal}
                    close={() => setIsBarcodeModalVisible(false)}
                    >
                        <div>
                            <div ref={contentRef} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", marginBottom: 20}}>
                                <Title inlineStyles={{marginTop: 10}}>{cells?.find(x => x.id == targetCellId)?.place}</Title>
                                {targetCellId != null && <Barcode value={targetCellId!.toString()} width={3} height={100} />}
                            </div>
                            <Button text="Распечатать" onClick={() => reactToPrintFn()}  inlineStyles={{margin: "20px auto 0 auto"}}/>
                        </div>
                </Modal>
            <ProductModal
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                products={products}
                isLoader={isProductsFetching}
                />
            <Modal 
                isLoader={isCreateCellLoading}
                inlineStyles={{width: 365}}
                    isOpen={isCellModalVisible} 
                    isCloseCloseIcon
                    close={() => setIsCellModalVisible(false)}
                >
                    <Title inlineStyles={{marginBottom: 20}}>Создание ячейки</Title>
                    <Select
                        classNames={styles.field}
                        options={warehouses?.map(el => ({value:el.name!, key: el.id!}))}
                        selectedOptionKey={selectedWarehouseId}
                        onChange={val => setSelectedWarehouseId(val.key)}
                        placeholder="Склад"
                        />
                    <Input 
                        classNamesContainer={styles.field}
                        value={cellName} 
                        onChange={e => setCellName(e.target.value)}
                        placeholder="Название ячейки"
                        />
                    <Select
                        classNames={styles.field}
                        options={cellTypes}
                        selectedOptionKey={cellTypes[cellType].key}
                        onChange={val => setCellType(cellTypes.indexOf(val))}
                        placeholder="Тип ячейки"
                        />
                    <Input type="number" placeholder="Длина см" classNamesContainer={styles.field} value={cellLength} onChange={e => setCellLength(e.target.value)}/>
                    <Input type="number" placeholder="Ширина см" classNamesContainer={styles.field} value={cellWidth} onChange={e => setCellWidth(e.target.value)}/>
                    <Input type="number" placeholder="Высота см" classNamesContainer={styles.field} value={cellHeight} onChange={e => setCellHeight(e.target.value)}/>
                    <Input type="number" placeholder="Объём см3" classNamesContainer={styles.field} value={(Number(cellHeight) * Number(cellWidth) * Number(cellLength)).toString()} onChange={e => {}}/>
                    <Button inlineStyles={{marginTop: 10}} text="Создать" onClick={createCellHandler}/>
            </Modal>

            <Modal
                isLoader={isCreateWarehouseLoading}
                isOpen={isWarehouseModalVisible}
                isCloseCloseIcon
                close={() => setIsWarehouseModalVisible(false)}
                >
                <Title inlineStyles={{marginBottom: 20}}>Создание склада</Title>
                <Select
                    classNames={styles.field}
                    options={organizations?.map(el => ({value: el.name!, key: el.id!}))}
                    selectedOptionKey={selectedOrganizationId}
                    onChange={val => setSelectedOrganizationId(val.key)}
                    placeholder="Организация"
                    />
                <Input placeholder="Наименование" classNamesContainer={styles.field} value={warehouseName} onChange={e => setWarehouseName(e.target.value)}/>
                <Input placeholder="Адресс" classNamesContainer={styles.field} value={warehouseAdress} onChange={e => setWarehouseAdress(e.target.value)}/>
                <Button text="Создать" onClick={createWarehouseHandler}/>
            </Modal>
            <Modal
                isLoader={isCreateOrgLoading}
                isOpen={isWarehouseOrganizationModalVisible}
                isCloseCloseIcon
                close={() => setIsWarehouseOrganizationModalVisible(false)}
                >
                <Title inlineStyles={{marginBottom: 20}}>Создание организации</Title>
                <Input placeholder="Наименование" classNamesContainer={styles.field} value={organizationName} onChange={e => setOrganizationName(e.target.value)}/>
                <Button text="Создать" onClick={createOrganizationHandler}/>
            </Modal>
        </>
        </Layout>
    )
};

export {CellsPage};