import {Container as SharedContainer} from "shared/ui/Container";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { ProductDetail, ProductDetailCell, ProductOutputModel, RedactPlacementBillModel, useGetCellsQuery, useGetProductsByIdDetailsQuery, useGetProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsPlacementBillMutation } from "api";
import React, { useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TextArea } from "shared/ui/TextArea";
import { NotMore } from "functions/math";
import { ProductModal } from "features/Modals/Product";
import { getImageUrlByStrings } from "functions/image";
import { useAppSelector } from "app/store/hooks/redux";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Остаток в ячейке', width: '15%'},
    {name: "Из", width: "10%" },
    {name: "В", width: "10%" },
    {name: 'Количество', width: '10%'},
];

const AddingPlacementBillPage = () => {
    const {warehouseId, warehouseOrganizationId} = useAppSelector(state => state.userReducer);

    const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsQuery();
    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState(warehouseOrganizationId);
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});

    const [newBill, setNewBill] = useState<RedactPlacementBillModel>({
        realizationDate: new Date().toISOString(),
        warehouseId: warehouseId ?? undefined,
    });
    const {data: allCells} = useGetCellsQuery({warehouseId: newBill!.warehouseId!}, {skip: newBill?.warehouseId == null});
    const {data: productsByWarehouse, isFetching: isProductsFetching} = useGetProductsQuery({warehouseId: newBill!.warehouseId!}, {skip: newBill?.warehouseId == null});
    const [addPlacementBill] = usePostBillsPlacementBillMutation();

    const addPlacementBillHandler = async () => {
        try {
            await addPlacementBill({
                redactPlacementBillModel: {...newBill,
                    billProducts: selectedProductCells.map(el => {
                        const quantity = quantities.find(c => c.productCellId == el.productCellId);
                        const toCell = toCellsForselectedProductCells.find(c => c.productCellId == el.productCellId);
                        return ({
                        productCellId: el.productCellId,
                        quantity: quantity?.quantity,
                        toCellId: toCell?.toCellId,
                    })})
                }
            }).unwrap();

            setNewBill({});
            navigate(LinkPagesEnum.PlacementBills);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const navigate = useNavigate();
    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [selectedProductIdForVisibleDetails, setSelectedProductsIdForVisibleDetails] = useState<number | null>(null);
    const {data: productDetails, isFetching: isProductDetailsFetching} = useGetProductsByIdDetailsQuery({id: selectedProductIdForVisibleDetails!, warehouseId: newBill.warehouseId!}, {skip: selectedProductIdForVisibleDetails == null || newBill.warehouseId == null});
    const [selectedDetails, setSelectedDetails] = useState<ProductDetail[]>([]);
    const [selectedDetailForVisibleCells, setSelectedDetailForVisibleCells] = useState<ProductDetail | null>(null);
    const [selectedProductCells, setSelectedProductCells] = useState<ProductDetailCell[]>([]);
    const [toCellsForselectedProductCells, setToCellsForselectedProductCells] = useState<{toCellId: number, productCellId: number}[]>([]);
    const [quantities, setQuantities] = useState<{quantity: number, productCellId: number}[]>([]);
    
    return (
        <Layout>
                <>
                    <div style={{display: "flex"}}>
                        <Button text="Назад" inlineStyles={{marginRight: 10}} className={styles.headerButtons} onClick={() => navigate(LinkPagesEnum.PlacementBills)}/>
                        <Button text="Добавить накладную" className={styles.headerButtons} onClick={addPlacementBillHandler}/>
                    </div>
                    <div className={styles.title}>
                        Информация
                    </div>
                    <SharedContainer inlineClasses={styles.modal__fieldsContainer}>
                        <>
                            <div className={styles.field}>
                                <Input 
                                    placeholder="Ожидаемая дата реализациим"
                                    type="date"
                                    value={newBill.realizationDate?.split('T')[0]}
                                    onChange={e => setNewBill({...newBill, realizationDate: (new Date(e.target.value).toISOString())})}
                                    inlineStylesContainer={{marginBottom: 10}}
                                    />
                                <Select 
                                    isFetching={isWarehouseOrganizationsFetching}
                                    placeholder="Складская организация"
                                    options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                    onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                    selectedOptionKey={selectedWarehouseOrganizationId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                                <Select 
                                    nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                    isFetching={isWarehousesFetching}
                                    placeholder="Склад"
                                    options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                    onChange={val => setNewBill({...newBill, warehouseId: val.key})}
                                    selectedOptionKey={newBill.warehouseId}
                                    inlineStyles={{marginBottom: 10}}
                                    />
                            </div>
                            <TextArea
                                classNamesContainer={styles.note}
                                placeholder="Коментарий"
                                value={newBill.note}
                                onChange={e => setNewBill({...newBill, note: e.target.value})}
                                />
                        </>
                    </SharedContainer>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            selectedProductCells?.map(cell => {
                                const product = productsByWarehouse?.find(x => x.id == cell.productId);
                                const toCell = toCellsForselectedProductCells.find(x => x.productCellId == cell.productCellId);
                                const quantity = quantities?.find(x => x.productCellId == cell.productCellId);

                                return (
                                    <TableItem classNames={styles.item} inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={getImageUrlByStrings(product?.images)} alt={product?.name!}/></TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}>{product?.name}</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}>{product?.barcode} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{cell.quantity} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}>{cell.cellPlace}</TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}>
                                                <Select 
                                                    classNames={styles.select} 
                                                    placeholder="ячейки" 
                                                    selectedOptionKey={toCell?.toCellId} 
                                                    options={allCells?.map(el => ({value: el.name!, key: el.id!}))} 
                                                    onChange={val => {
                                                        const index = toCellsForselectedProductCells.findIndex(x => x.productCellId == cell.productCellId);
                                                        if (index!= -1)
                                                            setToCellsForselectedProductCells(prevState => prevState.filter((_, i) => i!= index));
                                                        else
                                                            setToCellsForselectedProductCells(prevState => [...prevState, {toCellId: val.key!, productCellId: cell.productCellId!}]);
                                                    }}/>
                                            </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}>
                                                <Input 
                                                    type="number" 
                                                    inlineStyles={{width: 63}} 
                                                    onChange={val => {
                                                        const index = quantities.findIndex(x => x.productCellId == cell.productCellId);
                                                        if (index!= -1)
                                                            setQuantities(prevState => prevState.map((el, i) => i == index ? {quantity: NotMore(parseInt(val.target.value), cell.quantity!), productCellId: cell.productCellId!} : el));
                                                        else
                                                            setQuantities(prevState => [...prevState, {quantity: NotMore(parseInt(val.target.value), cell.quantity!), productCellId: cell.productCellId!}]);
                                                    } } 
                                                    value={quantity?.quantity.toString()}/>
                                            </TableItemContainer> 
                                        </>
                                    </TableItem>
                                )
                            })
                        }
                    </Table>
                    <ProductModal
                        isLoader={isProductDetailsFetching || isProductsFetching}
                        close={() => setAddProductModalVisible(false)}
                        detailsBack={() => setSelectedProductsIdForVisibleDetails(null)}
                        cellsBack={() => setSelectedDetailForVisibleCells(null)}
                        isOpen={addProductModalVisible}
                        products={productsByWarehouse}
                        onProductClick={(el) => { 
                            setSelectedProductsIdForVisibleDetails(el.id!);
                        }}
                        selectedProducts={selectedProducts}
                        details={selectedProductIdForVisibleDetails != null ? productDetails : null}
                        onDetailClick={(el) => setSelectedDetailForVisibleCells(el)}
                        selectedDetails={selectedDetails}
                        cells={selectedDetailForVisibleCells?.productDetailCells}
                        selectedCells={selectedProductCells}
                        onCellClick={(el) => { 
                            const product = productsByWarehouse!.find(p => p.id == el.productId)!;

                            if (selectedProductCells?.includes(el))
                            {
                                setSelectedProductCells(prevState => prevState.filter(x => x.productCellId != el.productCellId));

                                var idxForDelete = selectedProducts.indexOf(product);
                                if (idxForDelete != -1) {
                                    selectedProducts.splice(idxForDelete, 1);
                                }
                                
                                var idxForDelete2 = selectedDetails.indexOf(selectedDetailForVisibleCells!);
                                if (idxForDelete2 != -1) {
                                    selectedDetails.splice(idxForDelete2, 1);
                                }
                            }
                            else {
                                setSelectedProducts(prevState => [...prevState, product]);
                                setSelectedDetails(prevState => [...prevState, selectedDetailForVisibleCells!]);
                                setSelectedProductCells(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    );
};

export {
    AddingPlacementBillPage,
};