import styles from "./index.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import TableSelect from "entities/TableSelect";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { CellModel, useGetBillsGetAcceptanceBillsByProductBarcodeQuery, useGetBillsQuery, useGetCellsQuery, usePostProcessesAddProcessMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TerminalScan } from "widgets/TerminalScan";
import { useAppSelector } from "app/store/hooks/redux";
import { NavigateButton } from "shared/ui/NavigateButton";
import Title from "shared/ui/Title/Title";
import { BillType } from "enums/billType";
import Layout from "widgets/Layout/Layout";
import { BillStatusEnum } from "enums/billStatus";

enum Stage {
    Zero = 0,
    First = 1,
    Second = 2,
    Third = 3,
}

const InspectionPage = () => {
    const [stage, setStage] = useState(Stage.First);
    const [selectedAcceptanceCell, setSelectedAcceptanceCell] = useState<CellModel | null>();
    
    const {warehouseId} = useAppSelector(state => state.userReducer);

    const { data: acceptanceCells } = useGetCellsQuery({cellType: CellTypeEnum.Acceptance, warehouseId:warehouseId!}, {skip: warehouseId == null});
    const { data: acceptanceBills } = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Acceptance, warehouseId: warehouseId!}, {skip: warehouseId == null});

    const [scannedBarcode, setScannedBarcode] = useState("");
    const { data: acceptanceBillsByProductBarcode, isSuccess: isAcceptanceBillsByProductBarcodeSuccess } = useGetBillsGetAcceptanceBillsByProductBarcodeQuery({barcode: scannedBarcode}, {skip: stage != Stage.Second || scannedBarcode == ""});

    const navigate = useNavigate();

    const [createProcess] = usePostProcessesAddProcessMutation();

    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedAcceptanceCell?.id,
            }).unwrap();

            navigate({pathname: LinkPagesEnum.InspectionProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(()=> {
        if (isAcceptanceBillsByProductBarcodeSuccess) 
        {
            if (acceptanceBillsByProductBarcode != undefined && acceptanceBillsByProductBarcode?.length > 0) 
            {
                setStage(Stage.Third);
            }
            else 
            {
                alert("По данному баркоду товара нет приходных накладных");
            }
        }
    }, [acceptanceBillsByProductBarcode])

    useEffect(()=> {
        if (acceptanceCells?.length == 1)
        {
            setStage(Stage.Second);
            setSelectedAcceptanceCell(acceptanceCells[0]);
        }
    }, [acceptanceCells])

    if (warehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите складскую организацию и склад в настройках</Title>
            <NavigateButton
                to={LinkPagesEnum.Settings}
                text="Перейти в настройки"
                />
        </div>;
    }
    return (
        <Layout>
            <div className={styles.container}>
                {stage == Stage.First && 
                    <>
                        <div className={styles.text}>
                            Просканируйте ячейку приёмки
                        </div>
                        <div>
                            или
                        </div>
                        <TableSelect
                            options={acceptanceCells?.map(el => { return { cell: el, onClick: () => {
                                setSelectedAcceptanceCell(el);
                                setStage(Stage.Second);
                            }}})}
                            title="Выберите из списка"
                        />
                    </>
                }
                {stage == Stage.Second &&
                    <>
                        <div className={styles.title}>
                            Ячейка выбрана: {selectedAcceptanceCell?.name}
                        </div>
                        
                        <div className={styles.subTitle}>
                            Отсканируйте товар накладной или выберите её из списка.
                        </div>
                        <TableSelect 
                            options={acceptanceBills?.map(el => { return { bill: el, onClick: () => createProcesshandler(el.id!)}})}
                            title="Выберите накладную"
                        />
                        <TerminalScan
                            isFocus
                            onChange={barcodeVal => setScannedBarcode(barcodeVal)}
                            />
                    </>
                }
                {stage == Stage.Third &&
                    <>

                        <div className={styles.title}>
                            Ячейка выбрана: {selectedAcceptanceCell?.name}
                        </div>
                        
                        <div className={styles.subTitle}>
                            Накладные по данному баркоду, выберите нужную.
                        </div>

                        <TableSelect 
                            options={acceptanceBillsByProductBarcode!.map(el => { return { bill: el, onClick: () => createProcesshandler(el.id!)}})}
                            title="Выберите накладную"
                        />
                    </>
                }
            </div>
        </Layout>
        
    )
};

export {InspectionPage};