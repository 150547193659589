import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {Modal} from "shared/ui/Modal";
import {PlacementProduct} from "../../components/Product";
import {Button} from "shared/ui-mobile/Button";
import styles from "./Inspection.module.scss";
import { useGetProcessesPlacementProcessModelQuery, useGetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessQuery, usePostProcessesPlacementCompleteMutation, usePostProcessesPlacementRedactProcessEmployeeMutation } from "api";
import { getPercentColor } from "functions/color";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TerminalScan } from "widgets/TerminalScan";
import Layout from "widgets/Layout/Layout";
import { Input } from "shared/ui/Input";

export type ProcessRealTimeEmployeeType = {
    processId: number;
    employeeId: number;
    name: string | null;
    email: string;
};

const PlacementProcessPage = () => { 
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [processId] = useState(Number.parseInt(searchParams.get("processId")!));

    const [isModalForEndInspectionOpen, setIsModalForEndInspectionOpen] = useState(false);
    const { data: inspectionProcessModel, refetch } = useGetProcessesPlacementProcessModelQuery({processId: processId});
    const [redactProcessEmployeeQuery] = usePostProcessesPlacementRedactProcessEmployeeMutation();
    const {data: processRealTimeEmployees, isError: isRealTimeEmployeeError} = useGetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessQuery({processId: processId}, {pollingInterval: 3000});

    const onBarcodeScan = (barcode: string) => {
        var productBillByBarcode = inspectionProcessModel?.products?.find(item => item.barcode == barcode);
        
        if (!productBillByBarcode) {
            productBillByBarcode = inspectionProcessModel?.products?.find(item => item.factoryBarcode == barcode)
            if (!productBillByBarcode)
                return;
        }

        redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: productBillByBarcode.productProcessId, isIncrement: true}})
        .then(
            () => {
                refetch();
            }
        );
    }

    const onIncrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId, isIncrement: true}})
        refetch();
    }

    const onDecrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }
        
        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId, isIncrement: false}})
        refetch();
    }

    const [completeProcess] = usePostProcessesPlacementCompleteMutation();
    const onSubmit = async (requiredFull: boolean) => {
        try {
            await completeProcess({inspectionCompleteModel: {processId: processId, requiredFull}}).unwrap();
            navigate(LinkPagesEnum.Processes);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }
    
    const isFullBill = () => {
        if (inspectionProcessModel?.products?.find(pr => pr.quantity != pr.acceptanceQuantity) != null) {
            return false;
        }
        return true;
    }
    const [isModalOpened, setIsModalOpened] = useState(false);

    const [ awaitQuantity, setAwaitQuantity ] = useState(0);
    const [ quantity, setQuantity ] = useState(0);
    const [ quantityValue, setQuantityValue] = useState(0);
    const [ indexSelectedElement, setIndexSelectedElement] = useState(0);
    const [isAdd, setIsAdd] = useState(true);

    const onSomeIncrementPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[indexSelectedElement].productProcessId, isIncrement: true, quantity: quantityValue}});
        refetch();
        
        setQuantity(inspectionProcessModel!.products[indexSelectedElement]!.acceptanceQuantity! + quantityValue);
    }

    const onSetPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        const selectedProduct = inspectionProcessModel?.products[indexSelectedElement];

        if (quantityValue > selectedProduct.acceptanceQuantity!)
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId, isIncrement: true, quantity: quantityValue - selectedProduct.acceptanceQuantity!}});
        }
        else 
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId, isIncrement: false, quantity: selectedProduct.acceptanceQuantity! - quantityValue}});
        }
        refetch();
        
        setQuantity(quantityValue);
    }

    const isFocusTerminalScan = isModalOpened || isModalForEndInspectionOpen
    const inputRef = useRef<HTMLInputElement | null>(null);
    if (!isModalOpened)
    {
        inputRef?.current?.focus?.();
    }


    if (inspectionProcessModel == null) {
        return <div></div>;
    }

    return (
        <Layout>
        <div className={styles.inspection}>
            <div className={styles.employeesContainer}>
                <div className={styles.employeesTitle}>
                    Кто ещё в этом процессе:
                </div>
                {!isRealTimeEmployeeError ? 
                    <div>
                        {
                            processRealTimeEmployees?.map((el, idx) => <span className={styles.employee} key={el.employeeId}><span className={styles.number}>{idx + 1}. </span>{el.name} ({el.email})</span>)
                        }
                    </div>
                    : <div>
                        Ошибка
                    </div>}
            </div>
            <div className={styles.percentBar} style={{backgroundColor: getPercentColor(inspectionProcessModel!.percent!)}}>
                {inspectionProcessModel?.percent}%
            </div>
            <div className={styles.topButttonsContainer}>
                <Button
                    text="Сгруппировать"
                    onClick={()=> {}}
                />
            </div>
            <div className={styles.inspectionEnd}>
                <div className={styles.productsContainer}>
                    {
                        inspectionProcessModel?.products?.map((el,index) => <PlacementProduct 
                            name={el.name!} 
                            imgSrc={el.images ? el.images[0] : "assets/images/box.png" }
                            article={el.article!} 
                            toCellName={el.toCellName!}
                            fromCellName={el.fromCellName!}
                            barcode={el.barcode!} 
                            factoryBarcode={el.factoryBarcode!}
                            awaitQuantity={el.quantity!}
                            quantity={el.acceptanceQuantity!}
                            onIncrement={()=> onIncrementPress(index)}
                            onDecrement={()=> onDecrementPress(index)}
                            onAdd={() => {
                                setAwaitQuantity(el.quantity!);
                                setQuantity(el.acceptanceQuantity!);
                                setIsModalOpened(true);
                                setIndexSelectedElement(index);
                                setIsAdd(true);
                            }}
                            onSet={() => {
                                setAwaitQuantity(el.quantity!);
                                setQuantity(el.acceptanceQuantity!);
                                setIsModalOpened(true);
                                setIndexSelectedElement(index);
                                setIsAdd(false);
                            }}
                            />
                        )
                    }
                </div>
                
                <Modal
                    close={() => {
                        setAwaitQuantity(0);
                        setQuantity(0);
                        setIsModalOpened(false);
                    }} 
                    isOpen={isModalOpened}
                    >
                        <>
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <tbody>
                                    <tr>
                                        <td>Ожид. кол-во</td>
                                        <td>Скан. кол-во</td>
                                        <td>Ост. кол-во</td>
                                    </tr>
                                    <tr>
                                        <td>{awaitQuantity}</td>
                                        <td>{quantity}</td>
                                        <td>{awaitQuantity - quantity}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Input
                            value={quantityValue.toString()}
                            onChange={(e) => {
                                if (Number(e.target.value)) {
                                    setQuantityValue(Number(e.target.value))
                                } else {
                                    setQuantityValue(0)
                                }
                            }}
                            placeholder="Количество"
                        />
                        <Button 
                            inlineStyle={{margin: "10px 0 0 0"}}
                            text={isAdd ? "Добавить" : "Установить"}
                            onClick={() => {
                                isAdd 
                                    ? onSomeIncrementPress()
                                    : onSetPress();
                                setQuantityValue(0);
                            }}
                        />
                        </>
                </Modal>
                <Modal
                    inlineStyles={{maxWidth: "90%"}}
                    close={() => setIsModalForEndInspectionOpen(false)} 
                    isOpen={isModalForEndInspectionOpen}
                    >
                        <div className={styles.modalText}>
                            Завершить приёмку с расхождениями ? 
                        </div>
                        <Button 
                            text="Да"
                            onClick={() => onSubmit(false)}
                        />
                </Modal>
            </div>
            <div className={styles.bottomButtonsContainer}>
                <Button 
                    onClick={() => {
                        if (isFullBill()) {
                            onSubmit(true);
                        }
                        else {
                            setIsModalForEndInspectionOpen(true);
                        }
                    }} 
                    text="Завершить перемещение"
                    />
            </div>
            <TerminalScan isFocus={!isFocusTerminalScan} onChange={onBarcodeScan}/>
        </div>
        </Layout>
    )
};

export {PlacementProcessPage};