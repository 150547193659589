import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { ProductDetail, ProductOutputModel, RedactBillModel, useGetBillsByIdDetailsQuery, useGetBillsByIdQuery, useGetOrganizationsQuery, useGetProductsByIdDetailsQuery, useGetProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsRedactBillMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { NotMore } from "functions/math";
import { getImageUrlByStrings } from "functions/image";
import { NavigateButton } from "shared/ui/NavigateButton";
import { MiniTitle } from "shared/ui/MiniTitle";
import { ProductModal } from "features/Modals/Product";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Наименование", width: "20%"},
    {name: "Штрих-код", width: "10%"},
    {name: "Цвет", width: "5%"},
    {name: "Объём", width: "10%"},
    {name: "Особенности", width: "15%"},
    {name: "Остаток", width: "15%"},
    {name: "Количество", width: "15%"},
]

const transporters: OptionType[] = [
    {value: "Склад", key: 1},
    {value: "Самостоятельно", key: 2},
    {value: "Транспортная компания", key: 3},
];

const RedactShipmentBillPage = ({}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.ShipmentBills);
        }
    }, [])
    const [redactedBill, setRedactedBill] = useState<RedactBillModel>({});

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [quantities, setQuantities] = useState<{key: string, quantity: number}[]>([]);
    
    const {isClient} = useAppSelector(el => el.userReducer);

    const [redactBillQuery] = usePostBillsRedactBillMutation();
    const {data: organizations} = useGetOrganizationsQuery();
    
    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState<number | null>();
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});
    
    const {data: products, isFetching: isProductsFetching} = useGetProductsQuery({warehouseId: redactedBill!.warehouseId!, organizationId: redactedBill!.organizationId!}, {skip: redactedBill?.warehouseId == null || redactedBill?.organizationId == null});
    const {data: bill} = useGetBillsByIdQuery({id: Number(searchParams.get('billId'))});
    const {data: details, isFetching: isBillDetailsFetching} = useGetBillsByIdDetailsQuery({id: Number(searchParams.get('billId'))}, {skip: products == null || bill == null});

    useEffect(() => {
        if (details != null && products != null && bill != null) {
            setSelectedDetails(details);
            setSelectedProducts(products.filter(el => details.map(x => x.productId).includes(el.id)));
            setQuantities(bill!.productsBills!.map(productBill => ({key: details.find(x => x.productId == productBill.productId && x.packageTypeId == productBill.packageTypeId)?.key!, quantity: productBill.quantity!})));
        }
    }, [details]);

    useEffect(()=> {
        if (bill != null) {
            setRedactedBill({
                id: bill?.id,
                realizeDate: (new Date(bill?.realizationDate!)).toISOString(),
                organizationId: bill.organizationId,
                palletsCount: bill.palletsCount,
                boxexCount: bill.boxexCount,
                transporter: bill.transporter,
                warehouseId: bill.warehouseId,
                carId: bill.carId,
                note: bill.note,
                deliveryNote: bill.deliveryNote,
                externalBillId: bill.externalBillId,
                sender: bill.sender,
            });
            setSelectedWarehouseOrganizationId(bill.warehouse?.organizationId);
        }
    },[bill])

    const redactShipmentBill = async () => {
        try {
            await redactBillQuery({redactBillModel: {...redactedBill, products: selectedDetails.map(detail => {
                const quantity = quantities.find(quantity => quantity.key == detail.key)?.quantity;
                return {id: detail.productId, packageTypeId: detail.packageTypeId, quantity: quantity};
        })}}).unwrap();

            navigate(LinkPagesEnum.ShipmentBills);
        }
        catch(e) {
        }
    };

    const changeWarehouseId = (val: OptionType) => {
        setSelectedDetails([]);
        setQuantities([]);
        setSelectedProducts([]);
        setRedactedBill({...redactedBill, warehouseId: val.key});
    }

    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [selectedProductIdForVisibleDetails, setSelectedProductsIdForVisibleDetails] = useState<number | null>(null);
    const {data: productDetails, isFetching: isProductDetailsFetching} = useGetProductsByIdDetailsQuery({id: selectedProductIdForVisibleDetails!, warehouseId: redactedBill!.warehouseId}, {skip: (selectedProductIdForVisibleDetails == null || redactedBill.warehouseId == undefined)});
    const [selectedDetails, setSelectedDetails] = useState<ProductDetail[]>([]);
    
    return (
        <Layout>
                <>
                    <div>
                        <div className={styles.header}>
                            <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.ShipmentBills}/>
                            {isClient && <Button text="Сохранить" onClick={redactShipmentBill}/>}
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Информация
                                </MiniTitle>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                    <Select 
                                            placeholder="Складская организация"
                                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                            selectedOptionKey={selectedWarehouseOrganizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                            isFetching={isWarehousesFetching}
                                            placeholder="Склад"
                                            options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={changeWarehouseId}
                                            selectedOptionKey={redactedBill.warehouseId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            placeholder="Организация"
                                            options={organizations?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setRedactedBill({...redactedBill, organizationId: val.key!})}
                                            selectedOptionKey={redactedBill.organizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={redactedBill.externalBillId}
                                            onChange={e => setRedactedBill({...redactedBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                                        value={redactedBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Доставка
                                </MiniTitle>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={redactedBill.sender}
                                        onChange={e => setRedactedBill({...redactedBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setRedactedBill({...redactedBill, transporter: val.value})}
                                        selectedOptionKey={transporters.find(x => x.value == redactedBill.transporter)?.key}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={redactedBill.realizeDate?.split('T')[0]}
                                        onChange={e => setRedactedBill({...redactedBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={redactedBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setRedactedBill({...redactedBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={redactedBill.palletsCount?.toString()}
                                        onChange={e => setRedactedBill({...redactedBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={redactedBill.deliveryNote}
                                        onChange={e => setRedactedBill({...redactedBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>
                            <Table columns={columns} isFetching={isProductsFetching || isBillDetailsFetching}>
                                {
                                    selectedDetails!.map(detail => {
                                        const product = selectedProducts?.find(p => p.id == detail?.productId);

                                        return (<TableItem inlineStyles={{marginBottom: 10}}>
                                            <>
                                                <TableItemContainer width={columns[0].width}><img src={getImageUrlByStrings(product?.images)} className={styles.img}/></TableItemContainer>
                                                <TableItemContainer width={columns[1].width}><div className={styles.text}>{product?.name}</div></TableItemContainer>
                                                <TableItemContainer width={columns[2].width}><div className={styles.text}>{product?.barcode}</div></TableItemContainer>
                                                <TableItemContainer width={columns[3].width}><div className={styles.text}>{product?.color}</div></TableItemContainer>
                                                <TableItemContainer width={columns[4].width}><div className={styles.text}>{product?.capacity}</div></TableItemContainer>
                                                <TableItemContainer width={columns[5].width}><div className={styles.text}>
                                                    <div>
                                                        Организация: {detail.organizationName}
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        Склад: {detail.warehouseName}
                                                    </div>
                                                    {detail.consumables != null &&
                                                        <>
                                                        <br/>
                                                        <div>
                                                            Расходники:
                                                            {detail.consumables?.map(c => <>
                                                                <div>{c.name}/{c.quantity} ед.</div>
                                                            </>)}
                                                        </div>
                                                        </>
                                                    }
                                                    <br/>
                                                    <div>Количество: {detail.quantity}</div>
                                                </div></TableItemContainer>
                                                <TableItemContainer width={columns[6].width}><div className={styles.text}>{detail?.quantity}</div></TableItemContainer>
                                                <TableItemContainer width={columns[7].width}>
                                                    <Input 
                                                        type="number"
                                                        inlineStylesContainer={{width: 100}}
                                                        onChange={e => 
                                                            setQuantities(prevState =>
                                                                {   
                                                                    if (prevState.find(pv => pv.key == detail.key)) {
                                                                        return prevState.map(pv => pv.key == detail.key!
                                                                            ? {...pv, quantity: NotMore(Number(e.target.value), detail?.quantity)} 
                                                                            : pv
                                                                            )
                                                                    }
                                                                    return [...prevState, {key: detail.key!, quantity: NotMore(Number(e.target.value), detail?.quantity)}]
                                                                } 
                                                                )
                                                            } 
                                                        value={quantities!.find(q => q.key == detail.key!)?.quantity.toString()}
                                                        />
                                                </TableItemContainer>
                                            </>
                                        </TableItem>)})
                                }
                            </Table>
                        </div>
                    </div>
                    <ProductModal
                        isLoader={isProductsFetching || isProductDetailsFetching}
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        detailsBack={() => setSelectedProductsIdForVisibleDetails(null)}
                        products={products}
                        selectedProducts={selectedProducts}
                        onProductClick={(el) => { 
                            setSelectedProductsIdForVisibleDetails(el.id!);
                        }}
                        details={selectedProductIdForVisibleDetails != null ? productDetails : null}
                        selectedDetails={selectedDetails}
                        onDetailClick={(el) => { 
                            const product = products!.find(p => p.id == el.productId)!;

                            if (selectedDetails?.map(x => x.key).includes(el.key))
                            {
                                setSelectedDetails(prevState => prevState.filter(x => x.key != el.key!));

                                var idxForDelete = selectedProducts.indexOf(product);
                                if (idxForDelete != -1) {
                                    selectedProducts.splice(idxForDelete, 1);
                                }
                            }
                            else {
                                setSelectedProducts(prevState => [...prevState, product]);
                                setSelectedDetails(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    )
};

export {RedactShipmentBillPage};